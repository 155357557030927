
/**存放是否为下班时间，下班时间:禁止办理一切业务 */
export default {
  namespaced: true,
  state: {
    disable: false,
    loading: false
  },
  mutations: {
    setDisable (state, value) {
      state.disable = value;
    },
    setLoading (state, value) {
      state.loading = value;
    }
  },
  actions: {
    setDisable (con, value) {
      con.commit('setDisable', value);
    },
    setLoading (con, value) {
      con.commit('setLoading', value);
    }
  },
}