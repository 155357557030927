import manba from "manba";

manba.config({
  formatString: {
    //符号"-"间隔
    "y": "YYYY",//年
    "ym": "YYYY-MM",//年月
    "ymd": "YYYY-MM-DD",//年月日
    "md": "MM-DD",//月日
    "ymdhms": "YYYY-MM-DD hh:mm:ss",//年月日时分秒
    "mdhms": "MM-DD hh:mm:ss",//月日时分秒
    "hms": "hh:mm:ss",//时分秒
    "hm": "hh:mm",//时分

    //中文间隔
    "YM": "YYYY年MM月",//年月
    "YMD": "YYYY年MM月DD日",//年月日
    "YMDHMS": "YYYY年MM月DD日 hh点mm分ss秒",//年月日时分秒
    "MD": "M月D日",//月日
  }
})

/**
 * 删除时间上相等的部分
 * 例1：2020-01-02 05:05:5 去除年 01-02 05:05:5
 * 例2：2020-01-02 05:05:5 去除年-月-日 05:05:5
*/
manba.delEqual = (date) => {
  if (manba().format("ymd") == manba(date).format("ymd"))
    return manba(date).format("hms");
  else if (manba().format("y") == manba(date).format("y"))
    return manba(date).format("mdhms");
  else return manba(date).format("ymdhms");
}
/**
 * 删除时间上相等的部分
 * 例1：2020-01-02 05:05:5 去除年 01-02 05:05:5
 * 例2：2020-01-02 05:05:5 去除年-月-日 05:05:5
*/
manba.delEqual2 = (date) => {
  if (manba().format("ymd") == manba(date).format("ymd"))
    return "今天"
  else if (manba().format("y") == manba(date).format("y"))
    return manba(date).format("md");
  else return manba(date).format("ymd");
}


/**计算相差时间
 * return：
 * x年前
 * x个月前
 * x天前
 * x小时前
 * x分钟前
 * 刚刚
 */
manba.differTime = (date) => {
  let present = new Date();
  let m = manba(present).distance(manba(date), manba.MINUTE)
  let y = m / 12 / 30 / 24 / 60;
  let mm = m / 30 / 24 / 60;
  let d = m / 24 / 60;
  let h = m / 60;

  if (y >= 1) return y.toString().split('.')[0] + "年前";
  else if (mm >= 1) return mm.toString().split('.')[0] + "个月前";
  else if (d >= 1) return d.toString().split('.')[0] + "天前";
  else if (h >= 1) return h.toString().split('.')[0] + "小时前";
  else if (m >= 1) return m.toString().split('.')[0] + "分钟前";
  else return "刚刚";
}

/**倒计时
 *params:start开始时间， stop结束时间
 *paramsFormat:yyyy-MM-dd HH:mm:ss
 *return:x天x小时x分x秒
 */
manba.countDown = function (start, stop) {
  if (Object.prototype.toString.call(start) === "[object String]")
    // eslint-disable-next-line
    start = start.toString().replace(/\-/g, '/');
  if (Object.prototype.toString.call(stop) === "[object String]")
    // eslint-disable-next-line
    stop = stop.toString().replace(/\-/g, '/');

  var d = (manba(stop).time() - manba(start).time()) / 1000 / 60 / 60 / 24;
  var d1 = d.toString().split('.')[0];
  var h = (d - d1) * 24;
  var h1 = h.toString().split('.')[0];
  var m = (h - h1) * 60;
  var m1 = m.toString().split('.')[0];
  var s = (m - m1) * 60;
  var s1 = s.toString().split('.')[0];
  return d1 + "天" + h1 + "小时" + m1 + "分" + s1 + "秒";
}

/**时间戳转为时间
 * patam:value 时间戳
 */
manba.formatSeconds = function (value) {
  var secondTime = parseInt(value); // 秒
  var minuteTime = 0; // 分
  var hourTime = 0; // 小时
  if (secondTime > 60) { //如果秒数大于60，将秒数转换成整数
    //获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60);
    //获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(secondTime % 60);
    //如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      //获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60);
      //获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(minuteTime % 60);
    }
  }
  var result = "" + parseInt(secondTime) + "秒";
  if (minuteTime > 0) {
    result = "" + parseInt(minuteTime) + "分" + result;
  }
  if (hourTime > 0) {
    result = "" + parseInt(hourTime) + "小时" + result;
  }
  return result;
}

export default manba