export default {
  about: "Accueil",
  accomplish: "Compléter",
  account: "Compte",
  accountApplyList: "Création de compte",
  accountBusiness: "Création de compte",
  accountCancellation: "Fermeture de compte",
  accountDrawMoney: "Retrait de compte",
  accountList: "Liste des comptes",
  accountRemittanceNotAccount: "Envoi de compte",
  accountType: "Type de compte",
  add: "Créer",
  addAccount: "Création de compte",
  addBusiness: "Création d’opération",
  addConfig: "Création de configuration",
  addRemunerationRules: "Création de règle",
  addRole: "Création de profile",
  addServiceChargeRules: "Création de règle",
  address: "Adresse",
  all: "Tout",
  allianceBusiness: "Distributeur",
  allianceBusinessJoin: "Ouverture de compte distributeur",
  allianceBusinessQuit: "Fermeture de compte distributeur",
  alternatePhone: "Téléphone secondaire",
  amountInvolvedRange: "Intervalle de montant",
  applayRemark: "Remarque",
  applicationB: "Demande d'opération",
  applicationType: "Type d'opération",
  apply: "Demande",
  applyCode: "Code d'opération",
  applyId: "Numéro",
  applyInfo: "Information",
  areaConfig: "Pays et régions",
  auditor: "Validateur",
  backlog: "Encours",
  bankAccount: "Compte bancaire",
  bankName: "Nom de la banque",
  basicInfo: "Informations de base",
  batchRemittancePayeeNotAccount: "Envoi multiple",
  batchTransfer: "Transfert multiple",
  beneficiaryAddress: "Adresse du bénéficiaire",
  beneficiaryInfo: "Information du bénéficiaire",
  businessConfig: "Modification",
  businessHandling: "Opérations",
  businessLicense: "Registre du commerce",
  businessLicenseCode: "Numéro du registre de commerce",
  businessLicenseImage: "Photo du registre de commerce",
  businessManaged: "Gestion des opérations",
  businessMenu: "Menu des opérations",
  businessName: "Nom de l'opération",
  cancel: "Annuler",
  cancellationClientAccount: "Fermeture compte personnel",
  cancellationCompanyAccount: "Fermeture compte partenaire",
  cashNotAccount: "Envoi ordianire",
  channelAccountWithdrawalApplicationB: "Retrait de compte",
  channelCashWithdrawalB: "Retrait de cash",
  channelDealerManage: "Gestion des distributeurs",
  channelInformationInquiry: "Vérification des informations des distributeurs",
  channelMerchantAccountRechargeApplicationB: "Demande de recharge de compte",
  channelMerchantCashRechargeApplicationB: "Demande de recharge de cash",
  check: "Validation",
  checkBusiness: "Validations",
  checkOrg: "Validation d'organisation",
  checkPending: "Encours de validation",
  checkResult: "Résultat de validation",
  checkStatus: "État de validation",
  childBusiness: "Sous-opération",
  city: "Ville",
  close: "Fermer",
  code: "Code",
  confirm: "Confirmer",
  cooperativePartnerJoin: "Création compte partenaire",
  cooperativePartnerQuit: "Fermeture compte partenaire",
  country: "Pays",
  creationTime: "Date de création",
  customer: "Client",
  customerLogin: "Connexion",
  dealType: "Type d'opération",
  delete: "Supprimer",
  department: "Départment",
  deposit: "Dépôt cash",
  depositAmount: "Montant du dépôt",
  depositBusiness: "Opération de dépôt",
  depositInfo: "Information de dépôt",
  detailedAddress: "Adresse",
  detailedInfo: "Details d'information",
  directSaleStore: "Agence BNIF",
  directStoreAccount: "Compte agence BNIF",
  download: "Téléchargement",
  drawMoneyBusiness: "Opération de retrait",
  edit: "Modifier",
  editAccount: "Modification de compte",
  editBusiness: "Modification d'opération",
  editConfig: "Modification de configuration",
  editRemunerationRules: "Modification de clé de partage",
  editRole: "Modification de profile",
  editServiceChargeRules: "Modification de règle des frais",
  employeesAccount: "Compte employé",
  error: "Erreur",
  exceptional: "anormalité",
  explain: "Explication",
  extend: "Extension",
  externalDeal: "Opération externe",
  failing: "Échouer",
  familyAddress: "Adresse",
  firstName: "Prénoms",
  fixedPrice: "Montant fixe",
  fixedPriceSettlement: "Règlement de montant fixe",
  foldMenu: "Réduire le menu",
  forbidden: "Désactiver",
  franchiseStoreAccount: "Compte distributeur",
  getApproved: "Valider",
  have: "Oui",
  home: "Accueil",
  homeCountry: "Pays",
  idCard: "Carte d'identité",
  idCardBack: "Recto de carte d'identité",
  idCardFront: "Verso de carte d'identité",
  idNumber: "Numéro d'identité",
  ifThePsswordIsResetSuccessfullyItWillBeTheSameAsTheLoginAccount: "Si votre mot est réinitialisé avec succès, il sera le même pour le compte de connexion",
  image: "Photo",
  individualAccount: "Compte personnel",
  information: "Information",
  inputInfo: "Saisir l'information",
  inputPhoneOrAccount: "Saisir le numéro de téléphone ou du compte",
  internalDeal: "Opération interne",
  lastName: "Nom",
  legalPerson: "Personne légale",
  legalPersonFirstName: "Prénoms",
  legalPersonIdCard: "Carte d'identité",
  legalPersonImage: "Photo",
  legalPersonLastname: "Nom",
  level: "Niveau",
  linkPhone: "Numéro de téléphone",
  loading: "Chargement……",
  // loginAccount: "Compte de connexion",
  loaginPassword: "Mot de passe de connexion",
  lock: "Verrouer",
  lockAccount: "Verrouillage de compte",
  lockApply: "Verrouer le compte",
  logOutApply: "Déconnecter le compte",
  login: "Connexion",
  loginAccount: "Nom d’utilisateur",
  loginPassword: "Mot de passe de connexion",
  mainBusiness: "Acitivité principale",
  maritalStatus: "État civil",
  maritalStatus0: "Célibataire",
  maritalStatus1: "Marié",
  maritalStatus2: "Divorcé",
  merchantLogin: "Connexion du marchand",
  monetaryLimitation: "Limitation du montant",
  name: "Nom",
  nation: "Pays",
  nextStep: "Suivant",
  normal: "Normal",
  notApproved: "Non approuvé",
  notChargeNotConfig: "Sans frais, pas besoin de configuration",
  notHave: "Sans",
  notLoginPassword: "La taille du mot de passe doit être comprise entre 6 et 18 chiffres",
  notNull: "Ne pas pas être vide",
  note: "Message",
  notification: "Notification",
  notificationManage: "Notifications",
  number: "Numéro",
  numberCode: "Code numérique",
  openClientAccount: "Ouverture compte personnel",
  openCompanyAccount: "Ouverture compte partenaire",
  operateSuccessfully: "Opération réussie",
  operationFailure: "Opération échouée",
  org: "Organisation",
  orgAddress: "Adresse",
  orgId: "Numéro d'ordre",
  orgInfo: "Information de base",
  orgManaged: "Organisation",
  orgName: "Nom",
  orgType: "Type",
  orgUserList: "Liste d'utilisateurs",
  other: "Autres",
  otherTransactionsReversal: "Autres opérations d'annulation",
  parentNode: "Parent",
  partnerAccount: "Compte partenaire",
  password: "Mot de passe",
  payApplicationCheckB: "Validation de recharge",
  payeePayment: "Payer par le bénéficiare",
  payment: "Methode de payment",
  phone: "Téléphone",
  photograph: "Photo",
  platform: "Plateforme",
  platformAccount: "Compte plateforme",
  platformFee: "Frais de plateforme",
  platformFeeAmount: "Montant des frais",
  platformFeeProportion: "Proucentage des frais",
  please: "Veuillez",
  pleaseSelectRemunerationTule: "Veuillez sélectionner la règle de partage",
  pleaseSelectServiceChargeTule: "Veuillez séectionner la règle des frais d'opération",
  pleaseUploadPictures: "Veuillez charger la photo",
  plus: "Ajouter",
  post: "Titre",
  prevStep: "Précédent",
  proportion: "Pourcentage",
  proportionSettlement: "Calcul de pourcentage",
  proportionSettlementAmount: "Montant de calcul de poucentage",
  province: "Région",
  provincial: "Régional",
  query: "Recherche",
  queryAccountInfo: "Recherche des informations de compte",
  queryChannelRewardADaySum: "Total commission (Journalier)",
  queryChannelRewardAMonthSum: "Total commission (Mensuel)",
  queryChannelRewardDetails: "Détails de commission",
  queryDeal: "Recherche d'opération",
  queryDepositB: "Recherche d'opération de dépôt",
  queryPlatformRewardADaySum: "Total commission (Journalier)",
  queryPlatformRewardAMonthSum: "Total commission (Mensuel)",
  queryPlatformRewardDetails: "Détails de commission",
  queryReward: "Recherche de commission",
  queryTransferB: "Recherche d'opération de transfert",
  queryWithdrawalsB: "Recherche d'opération de retrait",
  reason: "Raison",
  refresh: "Rafraîchir",
  region: "Région",
  registerApply: "Demande d'ouverture de compte",
  registerCheck: "Validation d'ouverture de compte",
  registerTime: "Date d'ouverture",
  remark: "Remarque",
  remittance: "Envoi",
  remittanceAmount: "Montant d'envoi",
  remittanceBusiness: "Opération d'envoi",
  remittanceInfo: "Information d'envoi",
  remittanceRemittance: "Nombre d'envoi",
  remittanceSum: "Cumul d'envoi",
  remittancesDrawMoney: "Retrait d'envoi",
  remittancesReversal: "Annulation d'envoi de compte",
  remitterInfo: "Information de l'expéditeur",
  remitterPayment: "Payer par l'expéditeur",
  remove: "Supprimer",
  remuneration: "Commission",
  remunerationManaged: "Commissions",
  remunerationRules: "Règle de commission",
  requiredFields: "Champs obligatoires",
  resetLoginPassword: "Réinitialiser le mot de passe de connexion",
  resetPasswords: "Mot de passe",
  resourceMenu: "Menu des ressources",
  reversalBusiness: "Opération d'annulation",
  role: "Profile",
  roleManage: "Profiles",
  rolePermissionSet: "Autorisations du profile",
  routeName: "Nom de la route",
  searchable: "Peut être recherché",
  serviceCharge: "Frais d'opération",
  "service charge": "Frais d'opération",
  serviceChargeManaged: "Frais d'opération",
  serviceFeeRules: "Règle des frais d'opération",
  setAuthority: "Configuration d'autorisation",
  settlementAmount: "Montant d'opération",
  settlementRules: "Règle d'opération",
  sex: "Sexe",
  sex0: "Féminin",
  sex1: "Masculin",
  shortName: "Nom court",
  sourceFee: "Commssion de distributeur",
  sourceFeeAmount: "Montant de la commission",
  sourceFeeProportion: "Pourcentage de commission",
  speciesName: "Nom",
  status: "État",
  subminCheckResult: "Soumettre le résultat de validation",
  submit: "Soumettre",
  success: "Succès",
  sum: "Nombre",
  systemConfig: "Configuration",
  systemSet: "Paramètrage global",
  template: "Modèle",
  time: "Temps",
  today: "Aujourd'hui",
  total: "Total",
  towardsLeft: "A gauche",
  towardsRight: "A droite",
  transferAccount: "Transfert de compte",
  transferBusiness: "Opération de transfert",
  transferInfo: "Information de transfert",
  transferReversal: "Annulation de transfert",
  transferorInfo: "Information de l'expéditeur",
  transfersOverrunCheckB: "Validation du dépassement de limite du transfert",
  type: "Type",
  uniekeId: "Code d'identification unique",
  unlock: "Déverrouiller",
  unlockAccount: "Déverrouillage du compte",
  update: "Mis à jour",
  updateInformation: "Mis à jour des informations",
  upload: "Télécharger",
  uploadImage: "Télécharger la photo",
  urban: "Ville",
  user: "Utilisateur",
  userLogin: "Connexion",
  userName: "Nom d'utilisateur",
  verificationCode: "Code de vérification",
  warning: "Alerte",
  whetherToResetThe: "Réinitialisation",
  withdrawDepositCheckB: "Validation de recharge cash",
  withdrawalAmount: "Montant de retrait",
  withdrawalInfo: "Information de retrait",
  withdrawalsOverrunCheckB: "Validation du dépassement de limite recharge cash",
  withdrawerAddress: "Adresse",
  withdrawerInfo: "Information du client",
  maximumTenTabs: "Seulement 10 onglets peuvent être ouverts en même temps",
  pleaseEnterAccountOrTelephoneNumberQuery: "Veuillez entrer le numéro de compte ou de téléphone pour vérifier",
  customerList: "Liste des clients",
  companyAccount: "Compte entreprise",
  customerCode: "Code du client",
  customerName: "Nom et prénom",
  area: "Région",
  queryException: "Exception de requête",
  cardNumber: "Numéro de compte",
  accountAmount: "Solde du compte",
  AfterEnteringTheAmountItWillBAutomaticallyCalculated: "Le système calcule automatiquement après avoir saisi le montant",
  transactionId: "Numéro de transaction",
  amount: "Montant",
  messageAuthenticationCode: "Code de vérification SMS",
  incomeRecord: "Revenu",
  accountInfo: "Information du compte",
  noCommissionRuleWasFoundByBusinessNumber: "Selon le numéro d'operation, la règle de frais n'est pas trouvée",
  applyManage: "Gestion des demandes",
  rechargeApply: "Demande de recharge",
  rechargeApplyList: "Liste des demandes de recharge",
  transferRechargeApply: "Demnde de recharge de transfert",
  cashRechargeApply: "Demande de recharge de cash",
  withdrawDepositApply: "Demande de retrait",
  checkInfo: "Information de validation",
  checkRemark: "Remarques",
  applyAmount: "Montant de demande",
  applyTime: "Date de demande",
  applyUser: "Demandeur",
  bankTransferserial: "Rélévé des transferts bancaires",
  transactionInfoId: "Numero d'opération",
  transfeDate: "Date de trnsfert",
  verifyTime: "Date de validation",
  selectDateAndTime: "Sélection de date",
  cardManage: "Gestion des comptes",
  cardList: "Listes des cartes",
  accountCode: "Numéro du compte",
  range: "Intervalle",
  personal: "Personel",
  cashAmount: "Solde de caisse",
  cac: "Numéro de compte cash",
  frequencyLimit: "Limitation de frequence",
  sigleTradeLimit: "Limitation par transaction",
  maxTradyLimit: "Limitation du seuil",
  orgUserId: "Numero d'utilisateur",
  cardQrCode: "Code QR de la carte",
  cashQrCode: "Code QR du compte cash",
  calculating: "Calcul",
  accountName: "Nom du compte",
  cardRecord: "Rélévé de compte",
  afterAmount: "Solde après opération",
  beforeAmount: "Solde avant l'opération",
  businessType: "Type d'opération",
  transactionCode: "Code de transaction",
  financialStatements: "États financiers",
  remunerationStatements: "États des commission",
  pleaseCompleteTheInformationForFirstLogin: "Veuillez compléter les informations lors de votre première connexion",
  clickUpload: "Cliquez pour télécharger",
  dragTheFileHere: "Déplacez des fichiers vers ici",
  or: "Ou",
  pleaseCompleteYourInformationAfterTheApprovalOfTheDisplayFunction: "Veuillez compléter vos informations et la fonction pourra être affichée une fois validé",
  onlyJPGPNGFilesFanBeUploadedAndNoMoreThan500KB: "Seuls les fichiers jpg/png peuvent être téléchargés, et pas plus de 500 Ko",
  onlyUploadEXCELFileAndInXLSXOrXLSFormat: "Seuls les fichiers \"EXCEL\" peuvent être téléchargés, et ils sont au format xlsx, xls",
  youNeedToTeUploadAfterDeleting: "Re-télécharger après suppression",
  date: "Date",
  startDate: "Date début",
  endDate: "Date fin",
  detail: "Détail",
  daysStatistical: "Statistiques par jour",
  monthlyStatistics: "Statistiques par mois",
  orgInfoId: "Numéro de l'entreprise",
  platformRemunerationAmount: "Commission plateforme",
  sourceRemunerationAmount: "Commission d'agence",
  personalData: "Informations personelles",
  passwordManagement: "Gestion de mot de passe",
  logOut: "Déconnecter",
  ruleName: "Nom de la rvgle",
  personalCenter: "Centre personel",
  title: "Titre",
  content: "Contenu",
  unsent: "N'a pas ete envoyè",
  sent: "Déjà envoyé",
  general: "Ordianire",
  urgent: "Urgent",
  creatorName: "Nom de l'agent",
  createId: "ID de l'agent",
  orgCreated: "Compagny de l'agent",
  source: "Source",
  browseNumber: "Nombre de vues",
  addNotice: "Notification d'ajout",
  editNotice: "Notification d'èdition",
  sendNotice: "Notification d'envoie",
  homeVisible: "Page d'accueil visible",
  homeInvisible: "Page d'accueil non visible",
  clickNumber: "Nombre de clicks",
  noMore: "Y'a pas plus",
  cashManagement: "Gestion de cash",
  cashIssued: "Attribution caisse",
  cashToReceive: "Encaissement attribution",
  cashPayback: "Récupération de cash",
  cashRecords: "Trace de cash",
  cashHandIn: "Décaissement",
  cashHandInCheck: "Validation décaissement",
  cashHandInApply: "Demande d'encaissement cash",
  refuseHandIn: "Encaissement réjeté",
  consentHandIn: "Encaissement validé",
  recoveryCash: "Cash recouvré",
  transferRecord: "Transfert",
  inputTradingCode: "Numero d'opération",
  remittanceRecord: "Envoi",
  transactionAmount: "Montant d'opération",
  tradingTime: "Date d'opération",
  tradingRecord: "Opération",
  transactionRemark: "Remarques",
  cashRemittanceReversal: "Annulation d'envoi de caisse",
  accountRemittanceReversal: "Annulation d'envoi de compte",
  deposiReversal: "Annulation de dépôt de cash",
  withdrawalReversal: "Annulation de retrait de cash",
  submitReversal: "Demande d'annulation",
  receipt: "Reçu",
  sequenceNumber: "Numéro d'ordre",
  addOrg: "Création d’agence",
  batchOfRemittance: "Envoi multiple",
  select: "Sélection",
  applyOrgCusId: "Numéro du demandeur",
  orgCusName: "Nom de demandeur",
  selectPaymentMethodOfServiceFee: "Sélectionner la méthode de paiement des frais",
  printrRceipt: "Impression de reçu",
  operator: "Opérateur",
  operateOrg: "Agence",
  tradeAssistant: "Agent",
  addTradeAssistant: "Création d'agent",
  refundAmount: "Montant de remboursement",
  accountBalanceMustBeQqualTo: "Le solde de compte doit être égal à",
  orgUserName: "Nom d'utilisateur",
  customerId: "ID du client",
  unreceived: "Non retiré",
  received: "Retiré",
  receive: "Retrait",
  issuedCash: "Cash envoyé",
  reasonsForSubmission: "Raison de soumission",
  reasonsForRefusal: "Raison de refus",
  orgCode: "Numéro d'entreprise",
  creator: "Agent créateur",
  downloadEXCEL: "Télécharger EXCEL",
  applyOrg: "Demandeur",
  whereaboutsFund: "Destination de fonds",
  affiliationOrg: "Affiliation",
  orgMember: "Membre",
  accountStatus: "État de compte",
  franchiseStore: "Distributeur",
  // lockAccount: "Compte déactivé",
  // unlockAccount: "Compte activé",
  updatePassword: "Changer le mot de passe",
  oldPassword: "Ancien mot de passe",
  newPassword: "Nouveau mot de passe",
  affirmPassword: "Confirmer le mot de passe",
  businessStatistics: "Statistique des opérations",
  "password changed successfully": "Modification de mot de passe réussi avec succès",
  "password changed failed": "Modification de mot de passe a échoué",
  tradingHour: "Date d'opération",
  cardNo: "Numéro de carte",
  serviceChageAmout: "Frais d'opération",
  cardNoBalance: "Solde de la carte",
  income: "Crédit",
  expenditure: "Débit",
  customerAccountService: "Services aux clients à compte",
  batchTransferAccounts: "Transfert multiple",
  transferAccounts: "Transfert de compte",
  "upload file contents": "Téléchargement de fichier",
  compellation: "Nom et prénom",
  "reselect file": "Sélectionner encore un fichier",
  "successfully upload": "Téléchargement réussi",
  listVisible: "Liste des élémments visibles",
  listInvisible: "Liste des éléments invisibles",
  "Drag the file here or": "Télécharger le fichier ici, ou",
  "click Upload": "Cliquer pour télécharger",
  "Only JPG/PNG files can be uploaded and no more than 500KB": "Ne peut charger des fichiers jpg/png, et pas plus de 500kb",
  "You need to re-upload after deleting": "Vous auriez besoin de télécharger à nouveau après suppression",
  "Please enter the number (e.g., 123, 0.123)": "Saisir un nombre (Ex : 123, 0.123)",
  "Three decimal places at most": "Maximum 3 decimal",
  "The length must be equal to 2 significant digits": "Doit être un nombre à 2 chiffres",
  "The length must be equal to 3 significant digits": "Doit être un nombre à 3 chiffres",
  "Must be a pure number": "Doit être un nombre",
  "Please enter Arabic numerals between 10 and 99, not including decimals": "Veuillez saisir un chiffre arabe compris entre 10 et 99, sans décimales",
  "You cannot include decimals": "Pas de décimal",
  "Must be a pure number (can start with 0)": "Doit être un nombre (peut commencer par 0)",
  "Please select audit status": "Sélectionner l'état de validation",
  "The login account cannot be empty": "Le compte de connexion ne peut pas être vide",
  "Password length must not be less than 6 characters": "Le mot de passe ne peut pas être inférieur à 6 chiffres",
  "Captcha length is not equal to 4": "La longueur du code de vérification n'est pas égale à 4",
  "SMS verification code sent successfully": "Code de vérification SMS envoyé avec succès",
  "Name of Remitter": "Nom de l'expéditeur",
  "The remittance voucher": "Reçu d'envoi",
  "The remittance amount": "Montant d'envoi",
  "Transaction no.": "Numéro de transaction",
  "Handling fee calculation": "Calcul des frais de transaction",
  "Withdrawal SMS verification code": "Code de vérification SMS pour le retrait",
  "Source of business": "Source de transaction",
  "click to download": "Cliquer sur download",
  "No template": "Pas de modèle",
  "transfer-template": "Modèle de transfert",
  "remittance-template": "Modèle d'envoi",
  "Send SMS verification code": "Envoi de code de vérification",
  "transfer amount": "Montant de transfer",
  "The amount transferred must not be greater than the account balance": "Le montant de transfert ne peut pas être supérieur au solde du compte",
  "send it again": "Renvoyer",
  "organization login": "Agence ou Distributeur",
  "member login": "Compte client",
  "Switch success": "Basculement réussi",
  "cashLending": "Mise à disposition",
  "Verification code Receiving number": "Numéro de réception du code de vérification",
  "recent trading record": "Liste transactions récentes",
  "add record": "Ajouter",
  "acknowledging time": "Date de confirmation",
  "Payee org": "Agence bénéficiaire",
  "Payee": "Bénéficiaire",
  "to be confirmed": "À confirmer",
  "confirmed": "Confirmé",
  "Confirmed received": "Confirmé reçu",
  "in receipt of": "Reçu",
  "Please contact the piping personnel": "Veuillez contacter l'agent",
  "remittorName": "Nom de l'expéditeur",
  "payeeName": "Nom du bénéficiaire",
  "payeePhone": "Téléphone du bénéficiaire",
  "remittanceManagement": "Gestion des envois",
  "remittanceList": "Liste des envois",
  "remittance time": "Date d'envoi",
  "withdrawal time": "Date de retrait",
  "already withdrawal": "Retiré",
  "without withdrawal": "Non retiré",
  "edit payee info": "Modification",
  "query object": "Type de recherche",
  "to remitter": "Le code est envoyé à l'expéditeur",
  "My account information": "Les informations de mon compte",
  "cashAccountRechargeApply": "Recharge de caisse",
  "cashAccountRechargeCheck": "Validation de cash",
  "virtualAccountRechargeApply": "Recharge de compte",
  "virtualAccountRechargeCheck": "Validation de recharge",
  "Service Fee Payment Method": "Méthode de paiement des frais",
  "The trading data does not match the positive type": "Les données de transaction et d'annulation ne sont pas uniformes",
  "The transaction is not successful,not Cancel transaction": "Cette transaction est incomplète et ne peut pas être annulée",
  "Transaction in cancellation,Do not repeat operations": "Cette transaction est déjà encours d'annulation, veuillez ne pas répéter",
  "The transaction has been cancelled,Do not repeat operations": "Cette annulation est réussie, veuillez ne pas répéter",
  "Features are under development": "Fonction en cours de développement",
  "transaction voucher": "Reçu de transaction",
  "cash account": "Compte de caisse",
  "virtual account": "Compte virtuel",
  "trading status": "Statut de transaction",
  "Waiting transaction": "Opération en attente",
  "transaction progress": "Opération en attente",
  "transaction success": "Opération terminée",
  "transaction failing": "Opération échouée",
  "buy back progress": "Annulation encours",
  "buy back success": "Annulation réussie",
  "buy back failing": "Annulation échouée",

  "remittance reason": "Raison du transfert",
  "reason2": "Raison",
  "School aid": "Aide scolaire",
  "Family assistance": "Assistance familiale",
  "Building construction": "Construction immobilière",
  "Business settlement": "Règlement de biens",
  "Service settlement": "Règlement de service",
  "Back": "Retour",
  "The feature is under development": "En cours de développement",
  "Other business": "Autres opérations",
  "otherConfig": "Autres configurations",
  "Open the configuration": "Ouvrir la configuration",
  "Close the configuration": "Fermer la configuration",
  "Platform maintenance fee configuration": "Configuration des frais SMS",
  "Shop account": "Compte marchant",
  "The end time cannot be later than 90 days after the start time": "L'intervalle de fin ne doit pas depasser 90 jours",
  "Enter the keyword to filter": "Saisir les mots clés   pour filtrer",
  "city2": "Ville",
  "Please select business": "Sélectionner la transaction",
  "Transaction tax configuration": "Configuration de frais de timbre",
  "Transaction tax": "Frais de timbre",
  "transaction tax": "Frais de timbre",
  "Please select the remittance record": "Sélectionner un envoi",
  "Monthly deduction time": "Date prélèvement",
  "day": "Jour",
  "The default value is the first day of each month": "Par défaut le 1er du mois",
  "unlock Apply": "Activer",
  "Platform maintenance cost": "Frais de plateforme",
  "platform remuneration": "Commission de plateforme",
  "organization remuneration": "Commission d'agence",
  "withdrawal organization remuneration": "Commission de retrait d'agence",
  "Whether to refund the handling fee": "Remboursement des frais",
  "Refund fee": "Frais remboursés",
  "Non-refundable service fee": "Frais non-remboursés",
  "Please choose whether to refund the service fee": "Sélectionner la methode de remboursement des frais",
  "The transfer template needs to be uploaded": "Veuillez charger le fichier de transfert",

  "Privacy Policy": "Politique de confidentialité",
  "about us": "Présentation de BNIF",
  "services us": "Nos Services",
  "contact us": "Nos Contacts",
  "Download app": "Télécharger l’app",

  "forget the password": "Mot de passe oublié?",
  "Send verification code": "Envoyer le captcha",
  "Please enter your login account and mobile phone number": "Veuillez entrer le compte de connexion avec le numéro de téléphone mobile",
  "There is no handling charge for transactions under": 'Pas de frais pour les transactions inférieures à {money} ₣',
  "be on duty": "Ouverture de cession",
  "off duty": "Fermeture de cession",
  "It is out of hours and cannot handle business": "Le compte actuel est hors service et ne peut pas faire des affaires!",

  "Service fee discount": "Réduction sur les frais de manutention",
  "after discount service": "Frais de manutention préférentiels",
  "sales network": "Liste des agences",
  "salesNetwork": "Liste des agences",
  "orgRemittancesRanked": "Classement des agences",
  // "Remittances ranked": "Classement des transferts",
  "partnerRemittanceRanking": "Classement des partenaires",
  "network": "Réseaux",
  "Note: After successful operation, we need to wait for the approval of the platform and the organization, and the account will be received after the approval": "Attention: après l’opération réussie, vous devez attendre la vérification de la plate-forme et de l’agence. Après l’approbation de la vérification, vous arriverez au compte",


  "batchOperationReview": "Transactions multiples",
  "batchOperationApply": "Application de trading de volume",
  "transaction account": "compte courant",
  "transaction time": "Heures de trading",
  "transaction EXCEL name": "Nom EXCEL de la transaction",
  "transaction info": "Informations sur transactions",
  "proceeds info list": "Liste des informations de collecte",
  "Batch transfer review": "Audit de transfert de masse",
  "Batch remittance review": "Audit de transfert de masse",

  "Adding a Customer Account": "Création de partenaire",
  "platformForCustomer": "Création de partenaire",
  "platform opens customer accounts": "Plateforme ouvrir un compte client",

  "finance": "finances",
  "manager": "manager",
  "organizationMembersRanking": "Classement des membres institutionnels",

  //接口返回异常
  "100001:wrong phone": "Le numéro de téléphone ne correspond pas au compte",
  "100004:The sms code is incorrect.": "Le code SMS est incorrect",

  "100002:the account or password is empty": "Le nom ou le mot de passe ne doit pas être vide",
  "100003:The account has been locked": "Le compte a été bloqué",
  "100003:The account has been locked, please try again in 2 hours.": "Le compte a été bloqué, veuillez réessayer dans 2 heures",
  "100005:You need to log in again": "Veuillez vous reconnecter à nouveau",
  "100005:The login has expired and you need to login again": "Veuillez vous reconnecter à nouveau",

  "100004:Account or password error 1 times, also can retry 4 time.": "Le compte ou le mot de passe est erroné 1 fois, et vous pouvez réessayer 4 fois",
  "100004:Account or password error 2 times, also can retry 3 time.": "Le compte ou le mot de passe est erroné 2 fois, et vous pouvez réessayer 3 fois",
  "100004:Account or password error 3 times, also can retry 2 time.": "Le compte ou le mot de passe est erroné 3 fois, et vous pouvez réessayer 2 fois",
  "100004:Account or password error 4 times, also can retry 1 time.": "Le compte ou le mot de passe est erroné 4 fois, et vous pouvez réessayer 1 fois",
  "100004:The verification code is incorrect.": "Code de vérification incorrect",

  "200001:no data": "Pas de données",
  "200002:Required parameter, cannot be empty": "Paramètre obligatoire, ne peut pas être vide",
  "200003:serviceCharge not found": "Aucune règle de frais correspondante trouvée",
  "200003:withdarwMoneyCode parameter error": "Erreur de code de retrait SMS",
  "200003:parameter error": "Erreur de donnée",
  "200002:the bank information cannot be blank": "Les informations de banques ne doivent pas être vides",
  "200002:code is empty": "Code de vérification ne doit pas être vide ",
  "200002:the resource ids is empty": "Code de ressource est vide",
  "200003:payeeDetailsId parameter error": "Erreur des détails du bénéficiaire",
  "200003:transactionInfoId parameter error": "Erreur de code de transaction",
  "200003:cardNo parameter error": "Erreur de numéro de carte",
  "200003:cardId or cardNo parameter error": "Erreur de numéro de carte",
  "200003:startTime or endTime parameter error": "Erreur de date",
  "200003:applyId parameter error": "Erreur du numéro de demande",
  "200003:transfeTime parameter error": "Erreur de temps de transfert",
  "200003:orgInfoId parameter error": "Erreur des informations d'organisation",
  "200003:id parameter invalid": "Code invalide",
  "200003:orgId parameter error": "Erreur des informations d'organisation",
  "200003:customerId parameter error": "Erreur de code client",
  "200003:idCard parameter error": "Erreur de numero d'identité",
  "200003:country or region parameter error": "Erreur de pays ou région",
  "200003:orgInfoCode parameter error": "Erreur de code d'organisation",
  "200003:numberCode parameter error": "Erreur de code numérique",
  "200003:level parameter error": "Erreur de niveau",
  "200003:isOrgCus parameter error": "Erreur de sélection d'organisation du client",
  "200003:businessType parameter error": "Erreur de type de transaction",
  "200003:parentId parameter error": "erreur de code parental",
  "200003:settlementRules parameter error": "Erreur de règle de calcul",
  "200003:id parameter error": "Erreur de numero d'identité",
  "200003:status parameter error": "Erreur d'état",
  "200003:orgCum parameter error": "Erreur de code d'organisation du client",
  "200003:type parameter error": "Erreur de type de données",
  "200003:operationType parameter error": "Erreur de type d'opération",
  "200003:payServiceChargeType parameter error": "Erreur de paiement de frais",
  "200003:businessCode parameter error": "Erreur de code de transaction",
  "200003:remittorCardId or remittorCardNo parameter error": "Erreur de numéro de carte de l'expéditeur",
  "200003:remittorCardNo parameter error": "Erreur de carte de l'expéditeur",
  "200003:payeeCardNo parameter error": "Erreur de carte du bénéficiaire",
  "200003:payeeCardId or payeeCardNo parameter error": "Erreur de numéro de carte du bénéficiaire",
  "200003:orgUserId parameter error, there is no card information": "Erreur d'utilisateur,le compte non trouvé",
  "200005:permission denied": "Permission refusée",

  "300001:not sufficient funds": "Solde insuffisant",
  "300002:duplicate login name": "Ce nom de compte est déjà enregistré",
  "300003:The phone to repeat": "Ce numéro de téléphone est déjà enregistré",
  "300004:Operate too often": "L'opération est trop fréquente",
  "300005:no serviceCharge rule found": "Aucune règle de frais correspondante trouvée",
  "300006:sms code is incorrect or expired": "Code SMS incorrect ou expiré",
  "300001:remittor not sufficient funds": "Le solde de l'expéditeur est insuffisant",
  "300007:transaction tax anomaly": "Anomalie sur la taxe de transaction",

  "400001:no card account": "Aucune carte sur compte",
  "400001:serviceChargeAmount surpass amount": "Les frais dépassent le montant de transaction",
  "400001:payee card account unavailable": "Le compte bénéficiaire n'est pas actif",
  "400001:remittor card account unavailable": "Le compte expéditeur n'est pas actif",
  "400001:payee customer unavailable": "Le status du bénéficiaire n'est pas actif",
  "400001:remittor customer unavailable": "Le status de l'expéditeur n'est pas actif",
  "400001:The affiliation organization is not available": "L'organisation affiliée n'est pas active",
  "400001:apply record status error": "Erreur de status de demande",
  "400001:businessCode not found": "Le code de transaction est introuvable",
  "400001:service charge or remuneration not found": "Les frais de transaction et de commission est introuvable",
  "400001:new customer failure": "Ajout de client échoué",
  "400001:new customer role failed": "Echec d'ajout de profile",
  "400001:new customer transaction failed": "Echec d'ouverture de compte",
  "400001:new application failed": "Echec de demande d'ouverture de compte",
  "400001:failed to generate a card account": "Echec d'ajout de compte",
  "400001:there is no get customer information through customerId": "Les informations du client non retrouvées",
  "400001:customer status unavailable": "Le status de client n'est pas actif",
  "400001:card account not found": "Le compte est introuvable",
  "400001:customer card account status unavailable": "Le compte client n'est pas actif",
  "400001:amount greater than 5000": "Le montant dépasse 5000",
  "400001:amount less than 5000": "Le montant est inférieur à 5000",
  "400001:no serviceCharge rule found": "Aucune règle de frais correspondante trouvée",
  "400001:operation failure": "L'opération a échoué",
  "400001:No user account found": "Veuillez utiliser le compte agent pour opérer",
  "400001:(serviceChargeAmount)Calculating fees is different from passing in fees": "Anormalie de calcul des frais",
  "400001:Failed to operate customer account": "Annlation échouée",
  "400001:The transaction has been completed!": "Transactions terminées ne peuvent pas être annulées",
  "400001:serviceChargeAmount Calculation of different": "Erreur de calcul de frais",
  "400001:serviceCharge rule and remuneration rule  not found": "Règle de frais et de commission non retrouvée",
  "400001:the status doesn't need to change": "Le status n'a pas besoin d'être changé",
  "400001:already existed organization": "Organisation existe déjà",
  "400001:have balance": "Solde n'est pas nul",
  "400001:data repetition": "Donnée répetée",
  "400001:please check the account balance": "Vérifier le solde de compte",
  "400001:date must be limited": "Date dit être limitée",
  "400001:the query range cannot exceed 93 days": "L'intervalle de recherche ne doit pas exèder 93 jours",
  "400001:upload failed, file is empty": "Téléchargement échoué，aucun fichier",
  "400001:upload failed, unsupported suffix": "Téléchargement échoué，ne supporte pas le format",
  "400001:upload failed, not upload image type": "Téléchargement échoué，ne supporte pas le type d'image",
  "400001:the current organization is not available": "Organisation inactive",
  "400001:account unavailable": "Compte inactif",
  "400001:customer unavailable": "Client inactif",
  "400001:create transactionInfo failure": "Création ",
  "400001:please check the balance of the main account": "Vérifier le solde du compte",
  "400001:please check the cash account balance": "Vérifier le solde de la caisse",
  "400001:please check it,the status has been processed": "Confirmer le status，s'il n'a pas changé",
  "400001:remittance data does not exist": "Les données d'envoi n'existent pas",
  "400001:the code has expired": "Le code de retrait a échoué",
  "400001:no batch data": "Il n'ya pas les données multiple",
  "400001:please import XLS, XLSX format files of type Excle": "Importer un fichier Excel de format XLS, XLS",
  "400001:the number of imports is more than 10,000": "La quantité a dépasser 10 000",
  "400001:there is no record of a transaction": "Il n'y' pas les données de transaction",
  "400001:status error": "Erreur de status",
  "400001:customer's original organization profile does not exist": "Organisation liée au client n'existe pas",
  "400001:the amount of overrun": "Le montant dépasse la limite",
  "400001:the total amount of overrun": "Le montant total dèpasse la limite",
  "400001:the frequency of overrun": "La fréquence dépasse la limite",

  "500:An error occurred inside the program": "Une exception s'est produite dans le programme",
  "500:please contact the piping personnel": "Anomalie, contacter l'administrteur",
}