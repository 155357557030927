export default {
  about: "Home",
  accomplish: "Accomplish",
  account: "Account",
  accountApplyList: "List of account application",
  accountBusiness: "Business account",
  accountCancellation: "Account cancellation",
  accountDrawMoney: "Account withdrawal",
  accountList: "Account list",
  accountRemittanceNotAccount: "Account remittance",
  accountType: "Account type",
  add: "Create",
  addAccount: "Account creation",
  addBusiness: "Business creation",
  addConfig: "Configurtion creation",
  addRemunerationRules: "Create remuneration rule",
  addRole: "Create profile",
  addServiceChargeRules: "Create fees rule",
  address: "Address",
  all: "All",
  allianceBusiness: "Distributor",
  allianceBusinessJoin: "Distributor account creation",
  allianceBusinessQuit: "Distributor account closing",
  alternatePhone: "Secondary phone",
  amountInvolvedRange: "Amount range",
  applayRemark: "Application remarks",
  applicationB: "Transaction apply",
  applicationType: "Transaction type",
  apply: "Application",
  applyCode: "Transaction code",
  applyId: "Application number",
  applyInfo: "Application information",
  areaConfig: "Region creation",
  auditor: "Validator",
  backlog: "Processing",
  bankAccount: "Bank account",
  bankName: "Bank name",
  basicInfo: "Basic information",
  batchRemittancePayeeNotAccount: "Multiple remittance",
  batchTransfer: "Multiple transfer",
  beneficiaryAddress: "Beneficiary address",
  beneficiaryInfo: "Beneficiary information",
  businessConfig: "Transaction configuration",
  businessHandling: "Transaction",
  businessLicense: "Business license",
  businessLicenseCode: "Business license number",
  businessLicenseImage: "Business license photo",
  businessManaged: "Transaction management",
  businessMenu: "Transaction menu",
  businessName: "Transaction name",
  cancel: "Cancel",
  cancellationClientAccount: "Personal account closing",
  cancellationCompanyAccount: "Entreprise account closing",
  cashNotAccount: "Money remittance",
  channelAccountWithdrawalApplicationB: "Withdrawal account application",
  channelCashWithdrawalB: "Cash withdrawal application",
  channelDealerManage: "Distributor management",
  channelInformationInquiry: "Distributor information inquiry",
  channelMerchantAccountRechargeApplicationB: "Account recharge application",
  channelMerchantCashRechargeApplicationB: "Demande de recharge de cash",
  check: "Validation",
  checkBusiness: "Transaction validation",
  checkOrg: "Enterprise validation",
  checkPending: "Validation in progress",
  checkResult: "Validation result",
  checkStatus: "Validation status",
  childBusiness: "Sub-transaction",
  city: "City",
  close: "Close",
  code: "Code",
  confirm: "Confirm",
  cooperativePartnerJoin: "Partner account opening",
  cooperativePartnerQuit: "Partner account closing",
  country: "Country",
  creationTime: "Creation date",
  customer: "Customer",
  customerLogin: "Customer login",
  dealType: "Transaction type",
  delete: "Delete",
  department: "Department",
  deposit: "Cash deposit",
  depositAmount: "Deposit amount",
  depositBusiness: "Deposit transaction",
  depositInfo: "Deposit information",
  detailedAddress: "Address",
  detailedInfo: "Information details",
  directSaleStore: "BNIF branch",
  directStoreAccount: "BNIF branch account",
  download: "Download",
  drawMoneyBusiness: "Withdrawal transaction",
  edit: "Edit",
  editAccount: "Account editing",
  editBusiness: "Transaction editing",
  editConfig: "Configuration editing",
  editRemunerationRules: "Remuneration rule editing",
  editRole: "Profile editing",
  editServiceChargeRules: "Fees rule editing",
  employeesAccount: "Employee account",
  error: "Error",
  exceptional: "abnormal",
  explain: "Explanation",
  extend: "Extension",
  externalDeal: "External transaction",
  failing: "Fail",
  familyAddress: "Family address",
  firstName: "First name",
  fixedPrice: "Fixed amount",
  fixedPriceSettlement: "Settling fixed amount",
  foldMenu: "Fold menu",
  forbidden: "Deactivate",
  franchiseStoreAccount: "Distributor account",
  getApproved: "Validate",
  have: "Yes",
  home: "Home",
  homeCountry: "Country",
  idCard: "ID card",
  idCardBack: "Back of ID card",
  idCardFront: "Front of ID card",
  idNumber: "ID card number",
  ifThePsswordIsResetSuccessfullyItWillBeTheSameAsTheLoginAccount: "If your password is successfully reset, it will be the same for the login account",
  image: "Photo",
  individualAccount: "Personal account",
  information: "Information",
  inputInfo: "Enter information",
  inputPhoneOrAccount: "Enter the phone or account number",
  internalDeal: "Internal transaction",
  lastName: "Last name",
  legalPerson: "Legal person",
  legalPersonFirstName: "Legal person first name",
  legalPersonIdCard: "Legal person ID card",
  legalPersonImage: "Legal person photo",
  legalPersonLastname: "Legal person last name",
  level: "Level",
  linkPhone: "Phone number",
  loading: "Loading……",
  loaginAccount: "Login account",
  loaginPassword: "Login password",
  lock: "Lock",
  lockAccount: "Account lock",
  lockApply: "Apply account lock",
  logOutApply: "Account unlock",
  login: "Connection",
  loginAccount: "Login account",
  loginPassword: "Login password",
  mainBusiness: "Main activity",
  maritalStatus: "Marital status",
  maritalStatus0: "Single",
  maritalStatus1: "Married",
  maritalStatus2: "Divorced",
  merchantLogin: "Merchant login",
  monetaryLimitation: "Amount limitation",
  name: "Name",
  nation: "Country",
  nextStep: "Next",
  normal: "Normal",
  notApproved: "Not approved",
  notChargeNotConfig: "No charge, no need for configuration",
  notHave: "Without",
  notLoginPassword: "The password size must be between 6 and 18 digits",
  notNull: "Can not be empty",
  note: "Message",
  notification: "Notification",
  notificationManage: "Notification management",
  number: "Number",
  numberCode: "Numeric code",
  openClientAccount: "Personal account opening",
  openCompanyAccount: "Enterprise account opening",
  operateSuccessfully: "Transaction succeeded",
  operationFailure: "Transaction failed",
  org: "Organisation",
  orgAddress: "Organisation address",
  orgId: "Organisation number",
  orgInfo: "Organisation information",
  orgManaged: "Organisation management",
  orgName: "Organisation name",
  orgType: "Organisation type",
  orgUserList: "Users list",
  other: "Others",
  otherTransactionsReversal: "Other transaction cancellation",
  parentNode: "Parent",
  partnerAccount: "Partner account",
  password: "Password",
  payApplicationCheckB: "Recharge validation",
  payeePayment: "Pay by beneficiary",
  payment: "Payment method",
  phone: "Telephone",
  photograph: "Photo",
  platform: "Platform",
  platformAccount: "Platform account",
  platformFee: "Platform fees",
  platformFeeAmount: "Platform fees amount",
  platformFeeProportion: "Platform fees purcentage",
  please: "Please",
  pleaseSelectRemunerationTule: "Please select remuneration rule",
  pleaseSelectServiceChargeTule: "Please select transaction fee rule",
  pleaseUploadPictures: "Please upload photo",
  plus: "Add",
  post: "Title",
  prevStep: "Previous",
  proportion: "Purcentage",
  proportionSettlement: "Purcentage settlement",
  proportionSettlementAmount: "Amount of purcentage settlement",
  province: "Region",
  provincial: "Region wide",
  query: "Search",
  queryAccountInfo: "Search account information",
  queryChannelRewardADaySum: "Total branch commission (Daily)",
  queryChannelRewardAMonthSum: "Total branch commission (Monthly)",
  queryChannelRewardDetails: "Branch commission details",
  queryDeal: "Transaction search",
  queryDepositB: "Deposit transaction serch",
  queryPlatformRewardADaySum: "Total platform commission (Daily)",
  queryPlatformRewardAMonthSum: "Total platform commission (Monthly)",
  queryPlatformRewardDetails: "Platform commission details",
  queryReward: "Commission search",
  queryTransferB: "Transfer transaction search",
  queryWithdrawalsB: "Withdrawal transaction search",
  reason: "Reason",
  refresh: "Refresh",
  region: "Region",
  registerApply: "Account opening application",
  registerCheck: "Account opening validation",
  registerTime: "Opening date",
  remark: "Remarks",
  remittance: "Remittance",
  remittanceAmount: "Remittance amount",
  remittanceBusiness: "Remittance transaction",
  remittanceInfo: "Remittance information",
  remittanceRemittance: "Remittance number",
  remittanceSum: "Total od remittance",
  remittancesDrawMoney: "Remittance withdrawal",
  remittancesReversal: "Account remittance cancellation",
  remitterInfo: "Sender information",
  remitterPayment: "Pay by sender",
  remove: "Cancel",
  remuneration: "Commission",
  remunerationManaged: "Commission management",
  remunerationRules: "Commission rule",
  requiredFields: "Mandatory fields",
  resetLoginPassword: "Reset login password",
  resetPasswords: "Reset password",
  resourceMenu: "Resources menu",
  reversalBusiness: "Cancelling transaction",
  role: "Profile",
  roleManage: "Profile management",
  rolePermissionSet: "Profile autorisation",
  routeName: "Route name",
  searchable: "Can be searched",
  serviceCharge: "Transaction fees",
  serviceChargeManaged: "Transaction fees management",
  serviceFeeRules: "Transaction fee rule",
  setAuthority: "Autorisation configuration",
  settlementAmount: "Transaction amount",
  settlementRules: "Transaction rule",
  sex: "Sex",
  sex0: "Female",
  sex1: "Male",
  shortName: "Short name",
  sourceFee: "Distributor name",
  sourceFeeAmount: "Distributor commission amount",
  sourceFeeProportion: "Distributor commission purcentage",
  speciesName: "Name",
  status: "Status",
  subminCheckResult: "Submit validation result",
  submit: "Submit",
  success: "Success",
  sum: "Number",
  systemConfig: "System configuration",
  systemSet: "System setting",
  template: "Model",
  time: "Time",
  today: "Today",
  total: "Total",
  towardsLeft: "To left",
  towardsRight: "To right",
  transferAccount: "Account transfer",
  transferBusiness: "Transfer transaction",
  transferInfo: "Transfer information",
  transferReversal: "Transfer cancelling",
  transferorInfo: "Sender information",
  transfersOverrunCheckB: "Transfer over limit validation",
  type: "Type",
  uniekeId: "Unique identification code",
  unlock: "Unlock",
  unlockAccount: "Unlock account",
  update: "Update",
  updateInformation: "Distributor information update",
  upload: "Upload",
  uploadImage: "Upload photo",
  urban: "City",
  user: "User",
  userLogin: "User login",
  userName: "Username",
  verificationCode: "Verification code",
  warning: "Warning",
  whetherToResetThe: "Resetting",
  withdrawDepositCheckB: "Cash recharge validation",
  withdrawalAmount: "Withdrawal amount",
  withdrawalInfo: "Withdrawal information",
  withdrawalsOverrunCheckB: "Cash withdrawal over limit validation",
  withdrawerAddress: "Customer address",
  withdrawerInfo: "Customer information",
}