/**替换全部字符串 */
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, "gm"), s2);
}
/**num保留小数位数， separator隔断符号*/
String.prototype.formatMoney = function (num, separator) {
  return formatMoney(this, num, separator)
}
/**num保留小数位数， separator隔断符号*/
Number.prototype.formatMoney = function (num, separator) {
  return formatMoney(this, num, separator)
}
function formatMoney (val, num, separator) {
  separator = separator ? separator : " ";
  num = num >= 0 && num <= 20 ? num : 2;
  let vv = val.toString().split('.')[1];
  num = vv && vv.length > num ? vv.length : num;
  val = parseFloat((val + "").replace(/[^\d\.-]/g, "")).toFixed(num) + ""; //将金额转成比如 123.45的字符串
  var valArr = val.split(".")[0].split("").reverse() //将字符串的数变成数组
  const valFloat = val.split(".")[1]; // 取到 小数点后的值
  let valString = "";
  for (let i = 0; i < valArr.length; i++) {
    valString += valArr[i] + ((i + 1) % 3 == 0 && (i + 1) != valArr.length ? separator : ""); //循环 取数值并在每三位加个','
  }
  const money = valString.split("").reverse().join("") + (valFloat ? "." + valFloat : ''); //拼接上小数位
  return money
}
