import axios from 'axios';
import qs from 'qs';
import storage from './storage';
import store from '@/store/index'
import router from '../../router/routers';
import { Notification } from 'element-ui';

//构造request参数
let paramBilud = (param, dataType) => {
  let config = "" //{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }

  let p = {};
  for (let k in param) {
    if (!param[k] && param[k] !== 0)
      p[k] = undefined
    else if (Object.prototype.toString.call(param[k]) == "[object String]")
      p[k] = param[k].trim()
    else
      p[k] = param[k]
  }

  if (!dataType) {
    return { config: config, query: qs.stringify(p) }
  }
  else if (dataType.toLowerCase() == 'json') {
    return { config: config, query: p }
  }
}
/**接口链接正常（res.status===200） */
let apiNormal = (living, res) => {
  if (res.data.code == 100005) {
    let rouet = storage.local.get("loginName");
    router.push(rouet || { name: 'merchantLogin' })
  }
  if (res.data.code == 100007 || res.data.code == 100000 || res.data.code == 100008 || res.data.code == 100009) {
    store.state.system.showInformationPerfect = true;
  }
  living['success'] ? living.success(res.data) : '';
}
/**接口链接异常（res.status!==200） */
let apiAbnormal = (living, err) => {
  let e = living['error']
  if (e) living['error'](err)
  else console.error(err);

  Notification({ type: "error", title: "异常", message: "系统异常，请稍后再试" })
}

let ajax = {
  post: (living) => {
    let req = paramBilud(living.param, living.dataType);
    axios.post(living.url, req.query, req.config).then(res => {
      apiNormal(living, res);
    }).catch(err => {
      apiAbnormal(living, err);
    })
  },

  get: (living) => {
    let req = paramBilud(living.param, "json");
    let params = Object.assign({}, req.query);
    axios.get(living.url, { params }, req.config).then(res => {
      apiNormal(living, res);
    }).catch(err => {
      apiAbnormal(living, err);
    })
  },
  postLoading: () => { },
  getLoading: () => { },
}

export default ajax;