
export default {
  namespaced: true,
  state: {
    rules: {
      notNull: [{ required: true, message: 'notNull', trigger: 'blur' }]
    },
  },
  mutations: {
    getRules (state, translate) {
      let rules = JSON.parse(JSON.stringify(state.rules))
      for (let k in rules) {
        state.rules[k].forEach((t) => {
          t.message = translate(t.message)
        })
      }
    }
  },
  actions: {
    getRules (state, translate) {
      state.commit("getRules", translate)
    }
  },
}