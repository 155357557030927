import Vue from 'vue'
import Vuex from 'vuex'
import system from "./system/system"
import verify from "./verify/verify"
import style from "./style/style"
import format from "./format/format"
import globalParameter from "./globalParameter/globalParameter"
import business from './business/business'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    system,
    verify,
    style,
    format,
    globalParameter,
    business
  },
})
