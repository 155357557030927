import routers from "./routers";
import { handleBusiness } from "./routers";
import { Message } from "element-ui";
import vuex from '../store/index';
import org from '../api/org'
import storage from "@/assets/uilt/storage";

routers.beforeEach((to, from, next) => {
  let loginPattern = storage.local.get('loginName');
  if (!loginPattern || loginPattern?.name == 'customerLogin') {
    next();
    return;
  }

  let businesss = [{ name: 'businessHandling' }, ...handleBusiness];
  let isBusiness = businesss.find(item => item.name == to.name);

  /**需求：第一次打开的时候加载上班状态，实现：打开菜单列表时加载，当进入模块后在退回不加载上班状态 */
  if (to.name == 'businessHandling' && businesss.find(item => item.name == from.name)) {
    next();
  }
  /**判断是否为下班时间，若为下班时间则不能打开页面办理页面 */
  else if (isBusiness) {
    vuex.dispatch('business/setLoading', true);

    org.getOrgUser({
      success: res => {
        vuex.dispatch('business/setDisable', res.data.status == 3)
        vuex.dispatch('business/setLoading', false)

        //不管是否为下班时间都需要进入业务菜单页面
        if (to.name == 'businessHandling')
          next();
        console.log('----------vuex.state.business.disable------', vuex.state.business.disable)
        //非下班时间
        if (!vuex.state.business.disable) {
          console.log(handleBusiness);
          console.log(vuex.state.system.versionNumber);
          next();
        }
        //下班时间
        else {
          Message.warning('Le compte actuel est hors service et ne peut pas faire des affaires!');
          return;
        }
      }
    })
  }
  else
    next();
})