import ajax from '../assets/uilt/ajax';

let country = {
  /**查询区域数据 */
  getList: req => { return ajax.get({ url: "/back/country/get-list", param: req.param, success: req.success }) },
  /**新增区域数据 */
  add: req => { return ajax.post({ url: "/back/country/add", param: req.param, success: req.success }) },
  /**更新区域数据 */
  updateById: req => { return ajax.post({ url: "/back/country/update-by-id", param: req.param, success: req.success }) },
}

export default country;