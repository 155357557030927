export default {
  about: "关于",
  accomplish: "完成",
  account: "账户",
  accountApplyList: "账户申请列表",
  accountBusiness: "账户业务",
  accountCancellation: "销户",
  accountDrawMoney: "账户取款",
  accountList: "账户列表",
  accountRemittanceNotAccount: "账户汇款",
  accountType: "账户类型",
  add: "新增",
  addAccount: "新增账户",
  addBusiness: "新增业务",
  addConfig: "新增配置",
  addRemunerationRules: "新增酬金规则",
  addRole: "新增角色",
  addServiceChargeRules: "新增手续费规则",
  address: "地址",
  all: "全部",
  allianceBusiness: "加盟商",
  allianceBusinessJoin: "加盟渠道开户",
  allianceBusinessQuit: "加盟渠道销户",
  alternatePhone: "备用电话",
  amountInvolvedRange: "涉及金额范围",
  applayRemark: "申请备注",
  applicationB: "业务申请",
  applicationType: "申请类型",
  apply: "申请",
  applyCode: "申请码",
  applyId: "申请ID",
  applyInfo: "申请信息",
  areaConfig: "区域配置",
  auditor: "审核人",
  backlog: "待办",
  bankAccount: "银行账户",
  bankName: "银行名称",
  basicInfo: "基本资料",
  batchRemittancePayeeNotAccount: "批量汇款",
  batchTransfer: "批量转账",
  beneficiaryAddress: "收款人地址",
  beneficiaryInfo: "收款人信息",
  businessConfig: "业务配置",
  businessHandling: "业务办理",
  businessLicense: "营业执照",
  businessLicenseCode: "营业执照编码",
  businessLicenseImage: "营业执照照片",
  businessManaged: "业务管理",
  businessMenu: "业务菜单",
  businessName: "业务名称",
  cancel: "取消",
  cancellationClientAccount: "个人销户",
  cancellationCompanyAccount: "合作商销户",
  cashNotAccount: "现金汇款",
  channelAccountWithdrawalApplicationB: "渠道账户提现申请",
  channelCashWithdrawalB: "渠道现金提现",
  channelDealerManage: "渠道商管理",
  channelInformationInquiry: "渠道信息查询",
  channelMerchantAccountRechargeApplicationB: "渠道商账户充值申请",
  channelMerchantCashRechargeApplicationB: "渠道商现金充值申请",
  check: "审核",
  checkBusiness: "审核业务",
  checkOrg: "审核机构",
  checkPending: "待审核",
  checkResult: "审核结果",
  checkStatus: "审核状态",
  childBusiness: "子业务",
  city: "市籍",
  close: "关闭",
  code: "编码",
  confirm: "确定",
  cooperativePartnerJoin: "合作商开户",
  cooperativePartnerQuit: "合作商销户",
  country: "国家",
  creationTime: "创建时间",
  customer: "客户",
  customerLogin: "客户登录",
  dealType: "交易类型",
  delete: "删除",
  department: "部门",
  deposit: "现金存款",
  depositAmount: "存款金额",
  depositBusiness: "存款业务",
  depositInfo: "存款信息",
  detailedAddress: "详细地址",
  detailedInfo: "详细信息",
  directSaleStore: "直营店",
  directStoreAccount: "直营店账户",
  download: "下载",
  drawMoneyBusiness: "取款业务",
  edit: "编辑",
  editAccount: "编辑账户",
  editBusiness: "编辑业务",
  editConfig: "编辑配置",
  editRemunerationRules: "编辑酬金规则",
  editRole: "编辑角色",
  editServiceChargeRules: "编辑手续费规则",
  employeesAccount: "成员账户",
  error: "错误",
  exceptional: "异常",
  explain: "说明",
  extend: "扩展",
  externalDeal: "外部交易",
  failing: "失败",
  familyAddress: "家庭住址",
  firstName: "名",
  fixedPrice: "一口价",
  fixedPriceSettlement: "一口价结算",
  foldMenu: "折叠菜单栏",
  forbidden: "禁用",
  franchiseStoreAccount: "加盟店账户",
  getApproved: "通过审核",
  have: "有",
  home: "首页",
  homeCountry: "国籍",
  idCard: "身份证",
  idCardBack: "身份证背面",
  idCardFront: "身份证正面",
  idNumber: "身份证号",
  ifThePsswordIsResetSuccessfullyItWillBeTheSameAsTheLoginAccount: "如果密码重置成功，将与登录帐号保持一致",
  image: "图片",
  individualAccount: "个人账户",
  information: "消息",
  inputInfo: "填写信息",
  inputPhoneOrAccount: "输入电话号码或账户",
  internalDeal: "内部交易",
  lastName: "姓",
  legalPerson: "法人",
  legalPersonFirstName: "法人名",
  legalPersonIdCard: "法人身份证",
  legalPersonImage: "法人照片",
  legalPersonLastname: "法人姓",
  level: "层级",
  linkPhone: "联系电话",
  loading: "加载中……",
  loaginPassword: "登录密码",
  lock: "锁定",
  lockAccount: "锁定账户",
  lockApply: "锁定账户",
  logOutApply: "注销账户",
  login: "登录",
  loginAccount: "登录账户",
  loginPassword: "登录密码",
  mainBusiness: "主业务",
  maritalStatus: "婚姻状态",
  maritalStatus0: "未婚",
  maritalStatus1: "已婚",
  maritalStatus2: "离异",
  merchantLogin: "商家登录",
  monetaryLimitation: "金额限制",
  name: "名称",
  nation: "国",
  nextStep: "下一步",
  normal: "正常",
  notApproved: "未通过审核",
  notChargeNotConfig: "没有费用无需配置",
  notHave: "无",
  notLoginPassword: "密码长度因该在6~18位之间",
  notNull: "不能为空",
  note: "短信",
  notification: "通知",
  notificationManage: "通知管理",
  number: "编号",
  numberCode: "数字编号",
  openClientAccount: "个人开户",
  openCompanyAccount: "合作商开户",
  operateSuccessfully: "操作成功",
  operationFailure: "操作失败",
  org: "机构",
  orgAddress: "机构地址",
  orgId: "机构编号",
  orgInfo: "机构信息",
  orgManaged: "机构管理",
  orgName: "机构名称",
  orgType: "机构类型",
  orgUserList: "用户列表",
  other: "其它",
  otherTransactionsReversal: "其它交易冲正",
  parentNode: "父节点",
  partnerAccount: "合作商账户",
  password: "密码",
  payApplicationCheckB: "充值审核",
  payeePayment: "收款人支付",
  payment: "支付方式",
  phone: "电话",
  photograph: "照片",
  platform: "平台",
  platformAccount: "平台账户",
  platformFee: "平台酬金",
  platformFeeAmount: "平台酬金金额",
  platformFeeProportion: "平台酬金比例",
  please: "请",
  pleaseSelectRemunerationTule: "请选择酬金规则",
  pleaseSelectServiceChargeTule: "请选择服务费规则",
  pleaseUploadPictures: "请上传图片",
  plus: "添加",
  post: "职位",
  prevStep: "上一步",
  proportion: "比例",
  proportionSettlement: "比例结算",
  proportionSettlementAmount: "结算金额比例",
  province: "省",
  provincial: "省籍",
  query: "查询",
  queryAccountInfo: "查询账户信息",
  queryChannelRewardADaySum: "渠道酬金汇总（按天）",
  queryChannelRewardAMonthSum: "渠道酬金汇总（按月）",
  queryChannelRewardDetails: "渠道酬金明细查询",
  queryDeal: "交易查询",
  queryDepositB: "存款业务查询",
  queryPlatformRewardADaySum: "平台酬金汇总（按天）",
  queryPlatformRewardAMonthSum: "平台酬金汇总（按月）",
  queryPlatformRewardDetails: "平台酬金明细查询",
  queryReward: "酬金查询",
  queryTransferB: "转账业务查询",
  queryWithdrawalsB: "取款业务查询",
  reason: "原因",
  refresh: "刷新",
  region: "地区",
  registerApply: "开户申请",
  registerCheck: "开户审核",
  registerTime: "注册时间",
  remark: "备注",
  remittance: "汇款",
  remittanceAmount: "汇款金额",
  remittanceBusiness: "汇款业务",
  remittanceInfo: "汇款信息",
  remittanceRemittance: "汇款数量",
  remittanceSum: "汇款总数",
  remittancesDrawMoney: "汇款取款",
  remittancesReversal: "账户汇款冲正",
  remitterInfo: "汇款人信息",
  remitterPayment: "汇款人支付",
  remove: "移除",
  remuneration: "酬金",
  remunerationManaged: "酬金管理",
  remunerationRules: "酬金规则",
  requiredFields: "必填字段",
  resetLoginPassword: "重置登录密码",
  resetPasswords: "重置密码",
  resourceMenu: "资源菜单",
  reversalBusiness: "冲正业务",
  role: "角色",
  roleManage: "角色管理",
  rolePermissionSet: "角色权限",
  routeName: "路由名称",
  searchable: "可搜索的",
  serviceCharge: "手续费",
  "service charge": "手续费",
  serviceChargeManaged: "手续费管理",
  serviceFeeRules: "服务费规则",
  setAuthority: "设置权限",
  settlementAmount: "结算金额",
  settlementRules: "结算规则",
  sex: "性别",
  sex0: "女士",
  sex1: "先生",
  shortName: "名称简写",
  sourceFee: "渠道酬金",
  sourceFeeAmount: "渠道酬金金额",
  sourceFeeProportion: "渠道酬金比例",
  speciesName: "名称",
  status: "状态",
  subminCheckResult: "提交审核结果",
  submit: "提交",
  success: "成功",
  sum: "总数",
  systemConfig: "系统配置",
  systemSet: "系统设置",
  template: "模板",
  time: "时间",
  today: "今日",
  total: "合计",
  towardsLeft: "向左",
  towardsRight: "向右",
  transferAccount: "账户转账",
  transferBusiness: "转账业务",
  transferInfo: "转账信息",
  transferReversal: "转账冲正",
  transferorInfo: "转账人信息",
  transfersOverrunCheckB: "转账超限审核",
  type: "类型",
  uniekeId: "唯一识别码",
  unlock: "解锁",
  unlockAccount: "解锁账户",
  update: "更新",
  updateInformation: "更新渠道信息",
  upload: "上传",
  uploadImage: "上传图片",
  urban: "市",
  user: "用户",
  userLogin: "用户登录",
  userName: "用户姓名",
  verificationCode: "验证码",
  warning: "警告",
  whetherToResetThe: "是否重置",
  withdrawDepositCheckB: "提现审核",
  withdrawalAmount: "取款金额",
  withdrawalInfo: "取款信息",
  withdrawalsOverrunCheckB: "提现超限审核",
  withdrawerAddress: "取款人地址",
  withdrawerInfo: "取款人信息",
  maximumTenTabs: "最多可同时打开10个标签",
  pleaseEnterAccountOrTelephoneNumberQuery: "请输入账户或电话后再查询",
  customerList: "客户列表",
  companyAccount: "合作商账户",
  customerCode: "客户编号",
  customerName: "客户姓名",
  area: "地区",
  queryException: "查询异常",
  cardNumber: "卡号",
  accountAmount: "账户余额",
  AfterEnteringTheAmountItWillBAutomaticallyCalculated: "输入金额后系统自动计算",
  transactionId: "交易单号",
  amount: "金额",
  messageAuthenticationCode: "短信验证码",
  incomeRecord: "收入记录",
  accountInfo: "账户信息",
  noCommissionRuleWasFoundByBusinessNumber: "根据业务编号没有查询到手续费规则",
  applyManage: "申请管理",
  rechargeApply: "充值申请",
  rechargeApplyList: "充值申请列表",
  transferRechargeApply: "转账充值申请",
  cashRechargeApply: "现金充值申请",
  withdrawDepositApply: "提现申请",
  checkInfo: "审核信息",
  checkRemark: "审核备注",
  applyAmount: "申请金额",
  applyTime: "申请时间",
  applyUser: "申请用户",
  bankTransferserial: "银行转账流水",
  transactionInfoId: "交易信息编号",
  transfeDate: "转账时间",
  verifyTime: "审核时间",
  selectDateAndTime: "选择日期时间",
  cardManage: "卡管理",
  cardList: "卡列表",
  accountCode: "账户编码",
  range: "范围",
  personal: "个人",
  cashAmount: "现金余额",
  cac: "现金账户编号",
  frequencyLimit: "频率限制",
  sigleTradeLimit: "单笔限制",
  maxTradyLimit: "天最大限制",
  orgUserId: "机构用户编号",
  cardQrCode: "卡片二维码",
  cashQrCode: "现金账户二维码",
  calculating: "计算中",
  accountName: "账户名称",
  cardRecord: "卡账户流水",
  afterAmount: "操作后余额",
  beforeAmount: "操作前余额",
  businessType: "业务类型",
  transactionCode: "交易代码",
  financialStatements: "财务报表",
  remunerationStatements: "酬金报表",
  pleaseCompleteTheInformationForFirstLogin: "首次登录请补全资料",
  clickUpload: "点击上传",
  dragTheFileHere: "将文件拖到此处",
  or: "或者",
  pleaseCompleteYourInformationAfterTheApprovalOfTheDisplayFunction: "请完善您的信息,审核通过后即可展示功能",
  onlyJPGPNGFilesFanBeUploadedAndNoMoreThan500KB: "只能上传jpg/png文件，且不超过500kb",
  onlyUploadEXCELFileAndInXLSXOrXLSFormat: "只能上传\"EXCEL\"文件,且为xlsx,xls格式",
  youNeedToTeUploadAfterDeleting: "删除后需重新上传",
  date: "日期",
  startDate: "开始日期",
  endDate: "结束日期",
  detail: "明细",
  daysStatistical: "按天统计",
  monthlyStatistics: "按月统计",
  orgInfoId: "机构信息编号",
  platformRemunerationAmount: "平台酬金",
  sourceRemunerationAmount: "渠道酬金",
  personalData: "个人资料",
  passwordManagement: "密码管理",
  logOut: "退出",
  ruleName: "规则名称",
  personalCenter: "个人中心",
  title: "标题",
  content: "内容",
  unsent: "未发送",
  sent: "已发送",
  general: "普通",
  urgent: "紧急",
  creatorName: "创建人姓名",
  createId: "创建人ID",
  orgCreated: "创建人机构",
  source: "来源",
  browseNumber: "浏览次数",
  addNotice: "新增通知",
  editNotice: "编辑通知",
  sendNotice: "发送通知",
  homeVisible: "首页可见",
  homeInvisible: "首页不可见",
  clickNumber: "点击次数",
  noMore: "没有更多了",
  cashManagement: "现金管理",
  cashIssued: "现金下发",
  cashToReceive: "现金领取",
  cashPayback: "现金回收",
  cashRecords: "现金记录",
  cashHandIn: "现金上缴",
  cashHandInCheck: "现金上缴审核",
  cashHandInApply: "现金上缴申请",
  refuseHandIn: "拒绝上缴",
  consentHandIn: "同意上缴",
  recoveryCash: "收回现金",
  transferRecord: "转账记录",
  inputTradingCode: "输入交易单号",
  remittanceRecord: "汇款记录",
  transactionAmount: "交易金额",
  tradingTime: "交易时间",
  tradingRecord: "交易记录",
  transactionRemark: "交易备注",

  cashRemittanceReversal: "现金汇款冲正",
  accountRemittanceReversal: "账户汇款冲正",
  deposiReversal: "现金存款冲正",
  withdrawalReversal: "现金取款冲正",
  submitReversal: "提交冲正",
  receipt: "回执单",
  sequenceNumber: "序号",
  addOrg: "新增机构",
  batchOfRemittance: "批量汇款",
  select: "选择",
  applyOrgCusId: "申请组织编码",
  orgCusName: "申请组织名称",
  selectPaymentMethodOfServiceFee: "请选择手续费支付方式",
  printrRceipt: "回执单打印",
  operator: "操作员",
  operateOrg: "操作机构",

  tradeAssistant: "营业员",
  addTradeAssistant: "新增营业员",
  refundAmount: "退款金额",
  accountBalanceMustBeQqualTo: "账户余额必须等于",

  orgUserName: "机构用户姓名",
  customerId: "客户信息ID",
  unreceived: "未领取",
  received: "已领取",
  receive: "领取",
  issuedCash: "下发现金",
  reasonsForSubmission: "上缴理由",
  reasonsForRefusal: "拒绝理由",
  orgCode: "机构编码",
  creator: "创建人",
  downloadEXCEL: "下载EXCEL",
  applyOrg: "申请机构",
  whereaboutsFund: "资金去向",
  affiliationOrg: "归属机构",
  orgMember: "机构成员",
  accountStatus: "账户状态",
  franchiseStore: "加盟店",
  updatePassword: "修改密码",
  oldPassword: "旧密码",
  newPassword: "新密码",
  affirmPassword: "确认密码",
  businessStatistics: "业务统计",
  "password changed successfully": "密码修改成功",
  "password changed failed": "密码修改失败",
  tradingHour: "交易时间",
  cardNo: "卡号",
  serviceChageAmout: "手续费",
  cardNoBalance: "卡余额",
  income: "收入",
  expenditure: "支出",
  customerAccountService: "客户自有账户业务",
  batchTransferAccounts: "批量转账",
  transferAccounts: "账户转账",
  "upload file contents": "上传文件的内容",
  compellation: "姓名",
  "reselect file": "重选文件",
  "successfully upload": "上传成功",
  listVisible: "显示列表",
  listInvisible: "隐藏列表",
  "Drag the file here or": "将文件拖到此处，或",
  "click Upload": "点击上传",
  "Only JPG/PNG files can be uploaded and no more than 500KB": "只能上传jpg/png文件，且不超过500kb",
  "You need to re-upload after deleting": "删除后需重新上传",
  "Please enter the number (e.g., 123, 0.123)": "请输入数字(如：123、0.123)",
  "Three decimal places at most": "最多3位小数",
  "The length must be equal to 2 significant digits": "长度必须相等于2位有效数字",
  "The length must be equal to 3 significant digits": "长度必须相等于3位有效数字",
  "Must be a pure number": "必须为纯数字",
  "Please enter Arabic numerals between 10 and 99, not including decimals": "请输入 10 ~ 99 之间的阿拉伯数字，且不包能含小数",
  "You cannot include decimals": "不能包含小数",
  "Must be a pure number (can start with 0)": "必须为纯数字(可以以0开头)",
  "Please select audit status": "请选择审核状态",
  "The login account cannot be empty": "登录账户不能为空",
  "Password length must not be less than 6 characters": "密码长度不能小于6位",
  "Captcha length is not equal to 4": "验证码长度不等于4",
  "SMS verification code sent successfully": "短信验证码发送成功",
  "Name of Remitter": "汇款人姓名",
  "The remittance voucher": "汇款凭证 ",
  "The remittance amount": "汇款金额 ",
  "Transaction no.": "交易号",
  "Handling fee calculation": "正在计算手续费……",
  "Withdrawal SMS verification code": "取款短信验证码",
  "Source of business": "来源业务",
  "click to download": "点击下载",
  "No template": "没有模板",
  "transfer-template": "转账模板",
  "remittance-template": "汇款模板",
  "Send SMS verification code": "发送短信验证码",
  "transfer amount": "转账金额",
  "The amount transferred must not be greater than the account balance": "转出金额不能大于账户余额",
  "send it again": "重新发送",
  "organization login": "机构登录",
  "member login": "会员登录",
  "Switch success": "切换成功",
  "cashLending": "现金拆借",
  "Verification code Receiving number": "验证码接收号码",
  "recent trading record": "近期交易记录",
  "add record": "新增记录",
  "acknowledging time": "确认时间",
  "Payee org": "收款机构",
  "Payee": "收款人",
  "to be confirmed": "待确认",
  "confirmed": "已确认",
  "Confirmed received": "已确认收到",
  "in receipt of": "已收到",
  "Please contact the piping personnel": "请与配管人员联系",
  "remittorName": "汇款人姓名",
  "payeeName": "收款人姓名",
  "payeePhone": "收款人电话",
  "remittanceManagement": "汇款管理",
  "remittanceList": "汇款列表",
  "remittance time": "汇款时间",
  "withdrawal time": "取款时间",
  "already withdrawal": "已取款",
  "without withdrawal": "未取款",
  "edit payee info": "修改取款人信息",
  "query object": "查询对象",
  "to remitter": "验证码发送到汇款人手机",//越短越好
  "My account information": "我的账户信息",
  "cashAccountRechargeApply": "现金账户充值申请",
  "cashAccountRechargeCheck": "现金账户充值审核",
  "virtualAccountRechargeApply": "虚拟账户充值申请",
  "virtualAccountRechargeCheck": "虚拟账户充值审核",
  "Service Fee Payment Method": "手续费支付方式",
  "The trading data does not match the positive type": "交易数据与冲正类型不匹配",
  "The transaction is not successful,not Cancel transaction": "该交易未交易成功，不可冲正",
  "Transaction in cancellation,Do not repeat operations": "该交易正在冲正中，请勿重复冲正",
  "The transaction has been cancelled,Do not repeat operations": "该交易冲正成功，请勿重复冲正",
  "Features are under development": "功能正在研发中",
  "transaction voucher": "交易凭证",
  "cash account": "现金账户",
  "virtual account": "虚拟账户",
  "trading status": "交易状态",
  "Waiting transaction": "待交易",
  "transaction progress": "交易进行中",
  "transaction success": "交易完成",
  "transaction failing": "交易失败",
  "buy back progress": "冲正进行中",
  "buy back success": "冲正成功",
  "buy back failing": "冲正失败",
  "remittance reason": "汇款理由",
  "reason2": "理由",
  "School aid": "学校资助",
  "Family assistance": "家庭援助",
  "Building construction": "建筑施工",
  "Business settlement": "买卖清算",
  "Service settlement": "服务清算",
  "Back": "返回",
  "The feature is under development": "该功能正在研发中",
  "Other business": "其它业务",
  "otherConfig": "其它配置",
  "Open the configuration": "打开配置",
  "Close the configuration": "关闭配置",
  "Platform maintenance fee configuration": "平台维护费配置",
  "Shop account": "营业员账户",
  "The end time cannot be later than 90 days after the start time": "结束时间不能大于开始时间90天",
  "Enter the keyword to filter": "输入关键字可筛选",
  "city2": "城市",
  "Please select business": "请选择业务",
  "Transaction tax configuration": "交易税配置",
  "Transaction tax": "交易税",
  "transaction tax": "交易税",
  "Please select the remittance record": "请选择汇款记录",
  "Monthly deduction time": "每月扣款时间",
  "day": "日",
  "The default value is the first day of each month": "默认为每月1日",
  "unlock Apply": "解除锁定",
  "Platform maintenance cost": "平台维护费",
  "channel": "渠道",

  "Whether to refund the handling fee": "是否退还手续费",
  "Refund fee": "退还手续费",
  "Non-refundable service fee": "不退手续费",
  "Please choose whether to refund the service fee": "请选择是否退还手续费",
  "The transfer template needs to be uploaded": "需要上传转账模板",

  "Privacy Policy": "隐私政策",
  "about us": "关于我们",
  "services us": "我们服务",
  "contact us": "联系我们",
  "Download app": "APP下载",

  "forget the password": "忘记密码？",
  "Send verification code": "发送验证码",
  "Please enter your login account and mobile phone number": "请输入登录账户与手机号",
  "There is no handling charge for transactions under": '{money} ₣ 以下交易不收取手续费',
  "be on duty": "上班",
  "off duty": "下班",
  "It is out of hours and cannot handle business": "当前账户为下班状态，不能办理业务！",

  "Service fee discount": "手续费优惠",
  "after discount service": "优惠后手续费",
  "sales network": "营业网点",
  "salesNetwork": "营业网点",
  "network": "网点",
  "orgRemittancesRanked": "机构汇款排名",
  "ranking": "排名",
  "partnerRemittanceRanking": "合作伙伴汇款排名",
  "Note: After successful operation, we need to wait for the approval of the platform and the organization, and the account will be received after the approval": "注意：操作成功后需要等待平台与机构审核，审核通过后才会到账",

  "batchOperationReview": "批量交易审核",
  "batchOperationApply": "批量交易申请",
  "transaction account": "交易账户",
  "transaction time": "交易时间",
  "transaction EXCEL name": "交易EXCEL名",
  "transaction info": "交易信息",
  "proceeds info list": "收款信息列表",
  "Batch transfer review": "批量转账审核",
  "Batch remittance review": "批量汇款审核",

  "Adding a Customer Account": "添加客户账户",
  "platformForCustomer": "平台客户",
  "platform opens customer accounts": "平台开通客户账户",
  "finance": "财务",
  "manager": "经理",

  "organizationMembersRanking": "机构成员排名",

  //接口返回异常信息（res.msg）
  "100003:The account has been locked, please try again in 2 hours.": "账户密码输入错误超出限制，请2小时后再试",
  "100004:Account or password error 1 times, also can retry 4 time.": "帐号或密码错误1次，还可以重试4次",
  "100004:Account or password error 2 times, also can retry 3 time.": "帐号或密码错误2次，还可以重试3次",
  "100004:Account or password error 3 times, also can retry 2 time.": "帐号或密码错误3次，还可以重试2次",
  "100004:Account or password error 4 times, also can retry 1 time.": "帐号或密码错误4次，还可以重试1次",
  "100004:The verification code is incorrect.": "验证码不正确",

  "200001:no data": "没有数据",
  "200002:Required parameter, cannot be empty": "必选参数，不能为空",
  "200003:serviceCharge not found": "没有找到对应的手续费规则",
  "200003:withdarwMoneyCode parameter error": "短信取款码错误",
  "200003:parameter error": "数据错误",

  "300001:not sufficient funds": "余额不足",
  "300002:duplicate login name": "当前账户已被注册",
  "300003:The phone to repeat": "当前电话号码已注册",
  "300004:Operate too often": "操作过于频繁",
  "300005:no serviceCharge rule found": "没有匹配的手续费规则",
  "300006:sms code is incorrect or expired": "短信代码错误或过期",

  "400001:no serviceCharge rule found": "没有找到对应的手续费规则",
  "400001:operation failure": "操作失败",
  "400001:No user account found": "请使用营业员账户操作",
  "400001:(serviceChargeAmount)Calculating fees is different from passing in fees": "手续费计算异常",
  "400001:Failed to operate customer account": "冲正失败",
  "400001:The transaction has been completed!": "已完成的交易不可冲正",

  "500:An error occurred inside the program": "程序内部发生异常",


  //新增异常信息（res.msg）
  "100001:wrong phone": "手机号与账户不匹配",
  "100004:The sms code is incorrect.": "短信代码错误",

  "100002:the account or password is empty": "帐号或密码为空",
  "100003:The account has been locked": "账户被锁定",
  "100005:You need to log in again": "需要重新登录",
  "100005:The login has expired and you need to login again": "需要重新登录",

  "200002:the bank information cannot be blank": "银行信息不能为空",
  "200002:code is empty": "验证码不能都为空",
  "200002:the resource ids is empty": "资源编号为空",

  "200003:payeeDetailsId parameter error": "收款人详情编号参数错误",
  "200003:transactionInfoId parameter error": "业务编号参数错误",
  "200003:cardNo parameter error": "卡号参数错误",
  "200003:cardId or cardNo parameter error": "卡号或者卡编号参数错误",
  "200003:startTime or endTime parameter error": "开始或者结束时间参数错误",
  "200003:applyId parameter error": "申请编号参数错误",
  "200003:transfeTime parameter error": "转账时间参数错误",
  "200003:orgInfoId parameter error": "机构资料编号参数错误",
  "200003:id parameter invalid": "无效的编号",
  "200003:orgId parameter error": "机构编号参数错误",
  "200003:customerId parameter error": "客户编号参数错误",
  "200003:idCard parameter error": "身份证号参数错误",
  "200003:country or region parameter error": "国家或者地区参数错误",
  "200003:orgInfoCode parameter error": "机构编号参数错误",
  "200003:numberCode parameter error": "数字编号参数错误",
  "200003:level parameter error": "层级参数错误",
  "200003:isOrgCus parameter error": "客户机构选项参数错误",
  "200003:businessType parameter error": "交易类型参数错误",
  "200003:parentId parameter error": "父编号参数错误",
  "200003:settlementRules parameter error": "结算规则参数错误",
  "200003:id parameter error": "ID参数错误",
  "200003:status parameter error": "状态参数错误",
  "200003:orgCum parameter error": "客户机构选项参数错误",
  "200003:type parameter error": "类型参数错误",
  "200003:operationType parameter error": "operationType类型参数错误",
  "200003:payServiceChargeType parameter error": "手续费支付类型参数错误",
  "200003:businessCode parameter error": "业务编码参数错误",
  "200003:remittorCardId or remittorCardNo parameter error": "汇款人卡编号或者汇款人卡号参数错误",
  "200003:remittorCardNo parameter error": "汇款人卡号错误",
  "200003:payeeCardNo parameter error": "收款人卡号错误",
  "200003:payeeCardId or payeeCardNo parameter error": "收款人卡编号或者收款人卡号参数错误",
  "200003:orgUserId parameter error, there is no card information": "机构用户编号错误，未查到账户",
  "200005:permission denied": "没有操作权限",

  "300001:remittor not sufficient funds": "汇款人资金不足",
  "300007:transaction tax anomaly": "交易税功能异常",

  "400001:no card account": "没有卡账户",
  "400001:serviceChargeAmount surpass amount": "手续费超过操作金额",
  "400001:payee card account unavailable": "收款人卡账户不可用",
  "400001:remittor card account unavailable": "汇款卡账户不可用",
  "400001:payee customer unavailable": "收款人状态不可用",
  "400001:remittor customer unavailable": "汇款人状态不可用",
  "400001:The affiliation organization is not available": "归属机构不可用",
  "400001:apply record status error": "申请记录状态错误",
  "400001:businessCode not found": "业务编号未找到",
  "400001:service charge or remuneration not found": "未找到服务费或酬金",
  "400001:new customer failure": "新增客户失败",
  "400001:new customer role failed": "新增客户角色失败",
  "400001:new customer transaction failed": "新增开户业务失败",
  "400001:new application failed": "新增开户申请失败",
  "400001:failed to generate a card account": "新增客户卡账户失败",
  "400001:there is no get customer information through customerId": "没有找到客户信息",
  "400001:customer status unavailable": "客户状态不可用",
  "400001:card account not found": "未找到卡账户",
  "400001:customer card account status unavailable": "客户卡帐户状态不可用",
  "400001:amount greater than 5000": "金额大于5000",
  "400001:amount less than 5000": "金额小于5000",
  "400001:serviceChargeAmount Calculation of different": "手续费计算有误",
  "400001:serviceCharge rule and remuneration rule  not found": "未找到收费规则和报酬规则",
  "400001:the status doesn't need to change": "状态无需修改",
  "400001:already existed organization": "机构已经存在",
  "400001:have balance": "机构金额不为空",
  "400001:data repetition": "数据重复",
  "400001:please check the account balance": "请核对账户余额",
  "400001:date must be limited": "日期必须有限制",
  "400001:the query range cannot exceed 93 days": "查询范围不能超过93天",
  "400001:upload failed, file is empty": "上传失败，文件为空",
  "400001:upload failed, unsupported suffix": "上传失败，不支持的类型",
  "400001:upload failed, not upload image type": "上传失败，未上传图像类型",
  "400001:the current organization is not available": "当前机构不可用",
  "400001:account unavailable": "账户不可用",
  "400001:customer unavailable": "客户不可用",
  "400001:create transactionInfo failure": "创建业务失败",
  "400001:please check the balance of the main account": "请核对主账户余额",
  "400001:please check the cash account balance": "请核对现金账户余额",
  "400001:please check it,the status has been processed": "请确认状态，是否已经处理",
  "400001:remittance data does not exist": "汇款数据不存在",
  "400001:the code has expired": "取款码已失效",
  "400001:no batch data": "没有批量的数据",
  "400001:please import XLS, XLSX format files of type Excle": "请导入XLS、XLSX格式的Excle文件",
  "400001:the number of imports is more than 10,000": "数量超过1万",
  "400001:there is no record of a transaction": "没有交易记录",
  "400001:status error": "状态错误",
  "400001:customer's original organization profile does not exist": "客户归属机构不存在",
  "400001:the amount of overrun": "金额超限",
  "400001:the total amount of overrun": "总金额超限",
  "400001:the frequency of overrun": "次数超限",

  "500:please contact the piping personnel": "异常，请联系维护人员",

  //"service charge: ***,transaction tax: ***"   	（手续费：100, 交易税：100）
  "organization remuneration": "机构酬金",
  "platform remuneration": "平台酬金",
  "withdrawal organization remuneration": "取款机构酬金",
}