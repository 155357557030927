const tool = {
  /**深度拷贝 */
  depthCopy: function (val) {
    return JSON.parse(JSON.stringify(val))
  },
  /**计算手续费，param(规则，金额) */
  commissionCalculation: function (c, v) {
    if (!Number(v) || c.length < 1) return '';

    let valid = c.find(function (t) {
      let max = Number(t.maxValue), min = Number(t.minValue);
      return max >= v && min <= v;
    })
    if (!valid) return '';

    let a = '';
    if (valid.settlementRules == 1) {
      a = (valid.settlementAmount / 100 * v).toString();
    }
    else if (valid.settlementRules == 2)
      a = (valid.settlementAmount).toString();

    if (!a) return '';

    let s = a.split('.');
    if (!s[1]) a = a + '.00';
    else if (s[1].length == 1) a = a + '0';
    else if (s[1].length > 2) a = s[0] + '.' + s[1].substring(0, 2);

    return a;
  },
  formatMoney: function (value, num) {
    num = num > 0 && num <= 20 ? num : 2;
    let vv = value.toString().split('.')[1];
    num = vv && vv.length > num ? vv.length : num;
    value = parseFloat((value + "").replace(/[^\d\.-]/g, "")).toFixed(num) + ""; //将金额转成比如 123.45的字符串
    var valueArr = value.split(".")[0].split("").reverse() //将字符串的数变成数组
    const valueFloat = value.split(".")[1]; // 取到 小数点后的值
    let valueString = "";
    for (let i = 0; i < valueArr.length; i++) {
      valueString += valueArr[i] + ((i + 1) % 3 == 0 && (i + 1) != valueArr.length ? "," : ""); //循环 取数值并在每三位加个','
    }
    const money = valueString.split("").reverse().join("") + "." + valueFloat; //拼接上小数位
    return money
  }
}

export default tool;