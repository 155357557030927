import ajax from '../assets/uilt/ajax';

let org = {
  /**新增机构，如：直营店、加盟店开户 */
  addOrg: req => { return ajax.post({ url: "/back/org/add-org", param: req.param, success: req.success }) },
  /**新增机构资料信息,如：机构申请完善资料 */
  addOrgInfo: req => { return ajax.post({ url: "/back/org/add-org-info", param: req.param, success: req.success }) },
  /**新增机构登录用户(机构部门开户并生成卡账户) */
  addOrgUser: req => { return ajax.post({ url: "/back/org/add-org-user", param: req.param, success: req.success }) },

  /**机构销户 */
  cancellationOrg: req => { return ajax.post({ url: "/back/org/cancellation-org", param: req.param, success: req.success }) },
  /**修改密码 */
  changePassword: req => { return ajax.post({ url: "/back/org/change-password", param: req.param, success: req.success }) },
  /**机构用户锁定、解锁 */
  lockOrgUser: req => { return ajax.post({ url: "/back/org/lock-org-user", param: req.param, success: req.success }) },
  /**更新机构开户基础信息 */
  updateOrgById: req => { return ajax.post({ url: "/back/org/update-org-by-id", param: req.param, success: req.success }) },
  /**更新完善的机构申请信息资料 */
  updateOrgInfoById: req => { return ajax.post({ url: "/back/org/update-org-info-by-id", param: req.param, success: req.success }) },
  /**更新机构登录用户信息 */
  updateOrgUser: req => { return ajax.post({ url: "/back/org/update-org-user", param: req.param, success: req.success }) },

  /**分页查询完善的机构申请资料 */
  getOrgInfoPage: req => { return ajax.get({ url: "/back/org/get-org-info-page", param: req.param, success: req.success }) },
  /**分页查询平台开户的机构基础信息 */
  getOrgPage: req => { return ajax.get({ url: "/back/org/get-org-page", param: req.param, success: req.success }) },
  /**分页查机构登录用户 */
  getOrgUserPage: req => { return ajax.get({ url: "/back/org/get-org-user-page", param: req.param, success: req.success }) },
  /**查机当前登录的机构用户 */
  getOrgUser: req => { return ajax.get({ url: "/back/org/get-org-user", param: req.param, success: req.success }) },
  /**分页查机构登录用户日志 */
  listOrgUserLogPage: req => { return ajax.get({ url: "/back/org/list-org-userLog-page", param: req.param, success: req.success }) },
  /**查询当前机构资料 */
  getOrgInfo: req => { return ajax.get({ url: "/back/org/get-orgInfo", param: req.param, success: req.success }) },
  /**查询当前机构资料 */
  listOrgInfoNameOrCode: req => { return ajax.get({ url: "/back/org/list-org-info-nameOrCode", param: req.param, success: req.success }) },


  /**绑定机构手续费、酬金规则 */
  bindingOrgSR: req => { return ajax.post({ url: "/back/org/binding-org-ser-and-rem", param: req.param, success: req.success }) },
  /**查询机构已绑定的手续费、酬金规则 */
  listOrgSerAndRem: req => { return ajax.get({ url: "/back/org/list-org-ser-and-rem", param: req.param, success: req.success }) },

  /**修改机构状态 */
  updateOrgInfoStatus: req => { return ajax.post({ url: "/back/org/update-orgInfo-status", param: req.param, success: req.success }) },

  /**批量上下班 */
  batchOffWork: req => { return ajax.post({ url: "/back/org/batch-off-work", param: req.param, success: req.success }) },
}

export default org;