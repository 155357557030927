import Vue from 'vue'
// 1.引入vue-i18n国际化组件
import VueI18n from 'vue-i18n'
// 2.引入elementUI自带的en/zh-CN语言包
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import frLocale from 'element-ui/lib/locale/lang/de'
// 3.引入本项目自定义的语言包
import rtodEnLocale from './en'
import rtodZhLocale from './zh'
import rtodFrLocale from './fr'
// 4.引入elementUI本地化应用
import ElementLocale from 'element-ui/lib/locale'
// 5.创建语言包的json格式数据对象[包括:语言包对象]
const messages = {
  en: {
    ...enLocale,
    ...rtodEnLocale
  },
  zh: {
    ...zhLocale,
    ...rtodZhLocale
  },
  fr: {
    ...frLocale,
    ...rtodFrLocale
  }
}
Vue.use(VueI18n);
// 6.设置默认的本地语言为中文
const i18n = new VueI18n({
  locale: 'fr', // 设置语言包中包含的语言类型
  messages // 设置语言包对象
})
//重点：为了实现element插件的多语言切换
// 切换语言的方法:
// this.$i18n.locale='messages中的键:eg:en/zh'[在@click中不需要this]
ElementLocale.i18n((key, value) => i18n.t(key, value))
// 导出i18n语言包对象到Vue中注册用
export default i18n