export default {
  namespaced: true,
  state: {
    test: "测试",
    imgError: require('@/assets/image/Logo/logo2.png'),
    showInformationPerfect: false,
    versionNumber: "V 1.11.10",
    lang: "",
  },
  mutations: {
    setInformationPerfect (state, param) {
      state.showInformationPerfect = param;
    }
  },
  actions: {
    setInformationPerfect (state, param) {
      state.commit("setInformationPerfect", param)
    }
  },
}