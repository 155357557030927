export default {
  local: {
    get: function (key) {
      return JSON.parse(window.localStorage.getItem(key))
    },
    set: function (key, value) {
      window.localStorage.setItem(key, JSON.stringify(value))
    },
    remove: function (key) {
      window.localStorage.removeItem(key)
    }
  },
  session: {
    get: function (key) {
      return JSON.parse(window.sessionStorage.getItem(key))
    },
    set: function (key, value) {
      window.sessionStorage.setItem(key, JSON.stringify(value))
    },
    remove: function (key) {
      window.sessionStorage.removeItem(key)
    }
  }
}