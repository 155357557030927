import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/**主路由*/
const routes = [
  {
    path: '/merchant-login',
    name: 'merchantLogin',
    component: () => import('../views/login/MerchantLogin.vue'),
  }, {
    path: '/customer-login',
    name: 'customerLogin',
    component: () => import('../views/login/CustomerLogin.vue'),
  }, {
    path: '/test',
    name: 'test',
    component: () => import('../views/Test.vue'),
  }, {
    path: '/',
    name: 'layout',
    // redirect: "home",
    component: () => import('../views/Layout.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/home/Home.vue')
      }, {
        path: 'handle-business',
        name: 'businessHandling',
        component: () => import('../views/handleBusiness/BusinessHandling.vue'),
      }
    ]
  },
]

/**业务模块 */
const handleBusiness = [
  { /**个人客户开户 */
    path: 'account/open-client-account',
    name: 'openClientAccount',
    component: () => import('../views/handleBusiness/account/OpenClientAccount.vue')
  }, {/**合作商开户 */
    path: 'account/open-company-account',
    name: 'openCompanyAccount',
    component: () => import('../views/handleBusiness/account/OpenCompanyAccount.vue')
  }, { /**个人客户销户 */
    path: 'account/cancellation-client-account',
    name: 'cancellationClientAccount',
    meta: { params: { businessCode: 11 } },
    component: () => import('../views/handleBusiness/account/CancellationClientAccount.vue')
  }, { /**合作商销户 */
    path: 'account/cancellation-client-account',
    name: 'cancellationCompanyAccount',
    meta: { params: { businessCode: 13 } },
    component: () => import('../views/handleBusiness/account/CancellationClientAccount.vue')
  }, { /**汇款 */
    path: 'remittance/cash',
    name: 'cashNotAccount',
    component: () => import('../views/handleBusiness/remittance/CashNotAccount.vue')
  }, {
    path: 'remittance/account',
    name: 'accountRemittanceNotAccount',
    component: () => import('../views/handleBusiness/remittance/AccountRemittanceNotAccount.vue')
  }, {
    path: 'remittance/batch',
    name: 'batchRemittancePayeeNotAccount',
    component: () => import('../views/handleBusiness/remittance/BatchRemittancePayeeNotAccount.vue')
  }, { /**转账 */
    path: 'transfer/transfer-account',
    name: 'transferAccount',
    component: () => import('../views/handleBusiness/transfer/TransferAccount.vue')
  }, {
    path: 'transfer/batch',
    name: 'batchTransfer',
    component: () => import('../views/handleBusiness/transfer/BatchTransfer.vue')
  }, {  /**存款 */
    path: 'deposit/deposit',
    name: 'deposit',
    component: () => import('../views/handleBusiness/deposit/Deposit.vue')
  }, {/**取款 */
    path: 'draw-money/account-draw-money',
    name: 'accountDrawMoney',
    component: () => import('../views/handleBusiness/drawMoney/AccountDrawMoney.vue')
  }, {
    path: 'draw-money/remittances-draw-money',
    name: 'remittancesDrawMoney',
    component: () => import('../views/handleBusiness/drawMoney/RemittancesDrawMoney.vue')
  },
  /**冲正 */
  {//现金汇款冲正
    path: 'reversal',
    name: 'cashRemittanceReversal',
    meta: { params: { type: 1, busCode: [20], text: "cashRemittanceReversal" } },
    component: () => import('../views/handleBusiness/reversal/Transfer.vue')
  }, {//账户汇款冲正
    path: 'reversal',
    name: 'accountRemittanceReversal',
    meta: { params: { type: 2, busCode: [21, 22, 72], text: "accountRemittanceReversal" } },
    component: () => import('../views/handleBusiness/reversal/Transfer.vue')
  }, {//转账冲正
    path: 'reversal',
    name: 'transferReversal',
    meta: { params: { type: 3, busCode: [30, 31, 70, 71], text: "transferReversal" } },
    component: () => import('../views/handleBusiness/reversal/Transfer.vue')
  }, {//现金存款冲正
    path: 'reversal',
    name: 'deposiReversal',
    meta: { params: { type: 4, busCode: [40], text: "deposiReversal" } },
    component: () => import('../views/handleBusiness/reversal/Transfer.vue')
  }, {//现金取款冲正
    path: 'reversal',
    name: 'withdrawalReversal',
    meta: { params: { type: 5, busCode: [50, 51], text: "withdrawalReversal" } },
    component: () => import('../views/handleBusiness/reversal/Transfer.vue')
  },
  //客户账户业务
  {//账户转账
    path: 'handle-business',
    name: 'transferAccounts',
    component: () => import('../views/handleBusiness/customerAccountService/TransferAccounts.vue')
  }, {//批量转账
    path: 'handle-business',
    name: 'batchTransferAccounts',
    component: () => import('../views/handleBusiness/customerAccountService/BatchTransferAccounts.vue')
  }, {//批量转账
    path: 'handle-business',
    name: 'accountRemittance',
    component: () => import('../views/handleBusiness/customerAccountService/AccountRemittance.vue')
  }, {//批量汇款
    path: 'handle-business',
    name: 'batchOfRemittance',
    component: () => import('../views/handleBusiness/customerAccountService/BatchOfRemittance.vue')
  },
]

/** 营业员资金管理*/
const frontDeskManagement = [
  {//营业员现金分配
    path: 'front-desk-management/cash-issued',
    name: 'cashIssued',
    component: () => import('../views/frontDeskManagement/CashIssued.vue')
  }, {//营业员现金分配
    path: 'front-desk-management/cash-to-receive',
    name: 'cashToReceive',
    component: () => import('../views/frontDeskManagement/CashToReceive.vue')
  }, {//营业员现金上交申请
    path: 'front-desk-management/cash-handin',
    name: 'cashHandIn',
    component: () => import('../views/frontDeskManagement/CashHandIn.vue')
  }, {//管理者审核上交申请
    path: 'front-desk-management/cash-hand-in-check',
    name: 'cashHandInCheck',
    component: () => import('../views/frontDeskManagement/CashHandInCheck.vue')
  }, {//两个代理商之间现金挪用
    path: 'front-desk-management/cash-lending',
    name: 'cashLending',
    component: () => import('../views/frontDeskManagement/CashLending.vue')
  },
]

/**业务审核 */
const checkBusiness = [
  {
    path: 'check-business/account-apply-list',
    name: 'accountApplyList',
    component: () => import('../views/checkBusiness/AccountApplyList.vue')
  }, {
    path: 'check-business/recharge-apply-list',
    name: 'virtualAccountRechargeCheck',
    component: () => import('../views/checkBusiness/VirtualAccountRechargeCheck.vue')
  }, {
    path: 'check-business/cash-account-recharge-check',
    name: 'cashAccountRechargeCheck',
    component: () => import('../views/checkBusiness/CashAccountRechargeCheck.vue')
  }, {
    path: 'check-business/batch-operation-review',
    name: 'batchOperationReview',
    component: () => import('../views/checkBusiness/BatchOperationReview.vue')
  }, {
    path: 'check-business/batch-operation-apply',
    name: 'batchOperationApply',
    component: () => import('../views/checkBusiness/BatchOperationApply.vue')
  }
]

/**申请管理（如充值申请，提现申请等） */
const applyManage = [
  {
    path: 'apply-manage/recharge-apply',
    name: 'virtualAccountRechargeApply',
    component: () => import('../views/applyManage/VirtualAccountRechargeApply.vue')
  }, {
    path: 'apply-manage/cash-account-recharge-apply',
    name: 'cashAccountRechargeApply',
    component: () => import('../views/applyManage/CashAccountRechargeApply.vue')
  }
]

/**卡管理 */
const cardManage = [
  {
    path: 'card-manage/card-list',
    name: 'cardList',
    component: () => import('../views/cardManage/CardList.vue')
  }, {
    path: 'card-manage/card-record',
    name: 'cardRecord',
    component: () => import('../views/cardManage/CardRecord.vue')
  }
]

/**机构管理 */
const orgManaged = [
  {//自营店
    path: 'org-managed/direct-sale-store',
    name: 'directSaleStore',
    component: () => import('../views/orgManaged/DirectSaleStore.vue')
  }, {//加盟店
    path: 'org-managed/franchise-Store',
    name: 'franchiseStore',
    component: () => import('../views/orgManaged/FranchiseStore.vue')
  }, {//本机构成员
    path: 'org-managed/employees-account',
    name: 'employeesAccount',
    component: () => import('../views/orgManaged/EmployeesAccount.vue')
  }, {//个人客户
    path: 'org-managed/individual-account',
    name: 'individualAccount',
    component: () => import('../views/orgManaged/IndividualAccount.vue')
  }, {//合作商客户
    path: 'org-managed/partner-account',
    name: 'partnerAccount',
    component: () => import('../views/orgManaged/PartnerAccount.vue')
  }, {//平台客户
    path: 'org-managed/platform-for-customer',
    name: 'platformForCustomer',
    component: () => import('../views/orgManaged/PlatformForCustomer.vue')
  },
]
/**报表 */
const remuneration = [
  {
    path: 'finance/remuneration-statements',
    name: 'remunerationStatements',
    component: () => import('../views/finance/RemunerationStatements.vue')
  }, {
    path: 'finance/trading-record',
    name: 'tradingRecord',
    component: () => import('../views/finance/TradingRecord.vue')
  },
]

/**系统管理 */
const systemManaged = [
  {
    path: 'system-managed/notification',
    name: 'notificationManage',
    component: () => import('../views/systemManaged/Notification.vue')
  }, {
    path: 'system-managed/role',
    name: 'roleManage',
    component: () => import('../views/systemManaged/Role.vue')
  }, {
    path: 'system-managed/area-config',
    name: 'areaConfig',
    component: () => import('../views/systemManaged/AreaConfig.vue')
  }, {
    path: 'system-managed/business-config',
    name: 'businessManaged',
    component: () => import('../views/systemManaged/Business.vue')
  }, {
    path: 'system-managed/remuneration-managed',
    name: 'remunerationManaged',
    component: () => import('../views/systemManaged/RemunerationManaged.vue')
  }, {
    path: 'system-managed/service-charge-managed',
    name: 'serviceChargeManaged',
    component: () => import('../views/systemManaged/ServiceChargeManaged.vue')
  }, {
    path: 'system-managed/other-config',
    name: 'otherConfig',
    component: () => import('../views/systemManaged/OtherConfig.vue')
  },
]
/**主页上的账户信息 */
const accountInfo = [
  {
    path: 'account-info',
    name: 'accountInfo',
    component: () => import('../views/accountInfo/AccountInfo.vue')
  }, {
    path: 'password-manage',
    name: 'passwordManage',
    component: () => import('../views/accountInfo/PasswordManage.vue')
  }
]

/**汇款管理 */
const remittanceManagement = [
  {
    path: 'remittance-list',
    name: 'remittanceList',
    component: () => import('../views/remittanceManagement/remittanceList.vue')
  }, {
    path: 'org-remittances-ranked',
    name: 'orgRemittancesRanked',
    component: () => import('../views/remittanceManagement/orgRemittancesRanked.vue')
  }, {
    path: 'ranking-cooperative-remittance',
    name: 'partnerRemittanceRanking',
    component: () => import('../views/remittanceManagement/partnerRemittanceRanking.vue')
  },{
    path: 'organization-members-ranking',
    name: 'organizationMembersRanking',
    component: () => import('../views/remittanceManagement/OrganizationMembersRanking.vue')
  },
]

/** 网点 */
const network = [{
  path: 'sales-network',
  name: 'salesNetwork',
  component: () => import('../views/network/SalesNetwork.vue')
},]

const loyout = routes.find(function (rou) { return rou.name == "layout" });

loyout.children.push(...handleBusiness, ...frontDeskManagement, ...checkBusiness, ...applyManage, ...cardManage, ...systemManaged, ...orgManaged, ...remuneration, ...accountInfo, ...remittanceManagement, ...network);

export { handleBusiness };

export default new VueRouter({ routes });

