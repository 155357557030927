import country from '@/api/country'

export default {
  namespaced: true,
  state: {
    countryList: [],
    countryTree: [],
    loginInfo: {},
    cardInfo: {},
  },
  mutations: {
    getCountry (state) {
      country.getList({
        success: res => {
          if (res.code != 200 || !res.data) return;
          state.countryList = res.data;
          //转换list为tree
          let d = JSON.parse(JSON.stringify(res.data));
          let tree = d.filter(function (t) { return t.parentId == 0 });
          for (let i = 0; i < tree.length; i++) {
            let b = d.filter(function (t) { return t.parentId == tree[i].id });
            tree[i].children = b && b.length > 0 ? b : undefined;
            let c = tree[i].children;
            if (!c) continue;
            for (let i2 = 0; i2 < c.length; i2++) {
              let a = d.filter(function (t) { return t.parentId == c[i2].id });
              c[i2].children = a && a.length > 0 ? a : undefined;
            }
          }
          state.countryTree = tree;
        }
      })
    },
    setLoginInfo (state, info) {
      info.menu = undefined;
      state.loginInfo = info
    },
    setCardInfo (state, info) {
      state.cardInfo = { ...info };
      // console.log('-----setCardInfo-----', state.cardInfo)
    }
  },
  actions: {
    getCountry (state) { state.commit('getCountry') },
    setLoginInfo (state, info) { state.commit('setLoginInfo', info) },
    setCardInfo (state, info) { state.commit('setCardInfo', info) }
  }
}