import Vue from 'vue'
import App from './App.vue'
import router from './router/routers'
import './router'
import store from './store'
//语言切换
import i18n from './lang'
//ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './assets/libs/theme/index.css'
Vue.use(ElementUI)

import storage from "./assets/uilt/storage";
Vue.prototype.$storage = storage;

import tool from '@/assets/uilt/tool.js';
Vue.prototype.$tool = tool;

import manba from '@/assets/uilt/manbaDate';
Vue.prototype.$manba = manba;

import '@/assets/uilt/string'

import Print from 'vue-print-nb'
Vue.use(Print);

console.warn = function () { }

Vue.config.productionTip = false;
new Vue({ router, store, i18n, render: h => h(App) }).$mount('#app')
